import React, { useState } from 'react';
import ProviderLayout from '../../ProviderLayout';
import { Box, Typography, Button, CircularProgress, Modal, Backdrop, Fade, IconButton } from '@mui/material';
import SampleFile from '../../Image/SampleFile.xlsx';
import { uploadExcel } from '../../api/action';
import { useDispatch } from 'react-redux';
import CustomSnackbar from '../../Utils/CustomSnackbar';
import CloseIcon from '@mui/icons-material/Close';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export default function ExcelUpload() {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [excel, setExcel] = useState("");
    const [loading, setLoading] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalErrors, setModalErrors] = useState([]);
    const [modalMessage, setModalMessage] = useState("");
    const [email, setEmail] = useState(localStorage.getItem("userEmail"));
    const dispatch = useDispatch();

    const handleModalClose = () => {
        setModalOpen(false);
    };

    function getCurrentDateTime() {
        const now = new Date();
      
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const year = String(now.getFullYear()).slice(-2);
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
      
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
      }
      
      // Usage example
      console.log(getCurrentDateTime());

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setUploadedFile(file);
        // Implement file upload logic
        console.log('Uploaded file:', file);
    };

    const isFileUploaded = !!uploadedFile;


    const excelUpload = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('excel_file', uploadedFile);
        formData.append('email', email);
        formData.append('datetime', getCurrentDateTime());
        console.log("formData", formData);
        setLoading(true);
        dispatch(
            uploadExcel(
                formData,
                (data) => {
                    setLoading(false);
                    console.log(data?.data?.message)
                    if (data?.data?.message.includes("Data not uploaded")) {
                        setSnackbarMessage("There is mismatched of data format. Please try again.");
                        setSnackbarSeverity("error");
                        setSnackbarOpen(true);
                    } else {
                        setUploadedFile(null)
                        setSnackbarMessage("File Uploaded Successfully");
                        setSnackbarSeverity("success");
                        setSnackbarOpen(true);
                    }


                },
                (error) => {
                    setLoading(false);
                    const errorMessage = error.message === "Network Error"
                        ? error.message
                        : error.response?.data?.detail || error.response?.data?.errors?.map((item, index) => item) || "We are under maintenance. Please try later";

                    if (Array.isArray(error.response?.data?.errors)) {
                        setModalErrors(error.response.data.errors);
                        setModalMessage(error.response.data.message);
                        setModalOpen(true);
                    } else {
                        setSnackbarMessage(errorMessage);
                        setSnackbarSeverity("error");
                        setSnackbarOpen(true);
                    }
                }
            )
        );
    };


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };


    return (
        <ProviderLayout>
            <Box sx={{ px: 5, py: 2, mx: 5, my: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <a href={SampleFile} download="Sample_File.xlsx">
                            <Button variant="contained" disableRipple disableElevation sx={{ mt: 2, background: '#0093AF' }}>
                                Download Sample Excel
                            </Button>
                        </a>
                    </Box>

                </Box>
                <Box sx={{ background: '#0093AF', px: 3, borderRadius: '6px 6px 0px 0px', mt: 2 }}>
                    <Typography variant='h6' sx={{ color: '#fff', fontWeight: 'bold' }}>Excel Upload</Typography>
                </Box>
                <Box sx={{ px: 3, py: 2, border: '1px solid #ccc', }}>
                    {uploadedFile ? (
                        <React.Fragment>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                Uploaded File Details:
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                Name: {uploadedFile.name}
                            </Typography>
                            <Typography variant="body2">
                                Size: {uploadedFile.size} bytes
                            </Typography>
                            <Typography variant="body2">
                                Type: {uploadedFile.type}
                            </Typography>
                        </React.Fragment>
                    ) : (
                        <Box sx={{ minHeight: '13vh', textAlign: 'center', display: 'flex', justifyContent: 'center', verticalAlign: 'middle', alignItems: 'center' }}>
                            <Typography variant="body1">
                                Upload your Excel file to proceed.
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{}}>
                        <input
                            accept=".xlsx,.xls"
                            id="excel-upload"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileUpload}
                        />
                        <label htmlFor="excel-upload">
                            <Button component="span" variant="contained" disableRipple disableElevation sx={{ mt: 2, mr: 2 }}>
                                Upload Excel File
                            </Button>
                        </label>
                    </Box>
                    <Box sx={{}}>
                        <Button
                            component="span"
                            variant="contained"
                            disableRipple
                            disableElevation
                            sx={{ mt: 2, mr: 2 }}
                            disabled={!isFileUploaded}
                            onClick={excelUpload}
                        >
                            {loading ? <CircularProgress size={24} sx={{ color: '#fff', }} /> : "Save"}
                        </Button>
                    </Box>
                </Box>

                <CustomSnackbar
                    open={snackbarOpen}
                    handleClose={handleSnackbarClose}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={modalOpen}
                    onClose={handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={modalOpen}>
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 600,
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                            borderRadius:'8px',
                            border:'none'
                        }}>
                            <Box sx={{display:'flex', justifyContent:'space-between', verticalAlign:'middle', alignItems:'center'}}>
                            <Typography id="transition-modal-title" variant="h6" component="h2" sx={{display:'flex', justifyContent:'left', verticalAlign:'middle', alignItems:'center', color:'#0093AF'}}>
                                {modalMessage} <PriorityHighIcon size={12} sx={{color:'#cc3300', fontSize:'18px' }}/>
                            </Typography>
                            <IconButton onClick={handleModalClose}>
                                <CloseIcon/>
                            </IconButton>
                            </Box>
                            <Box id="transition-modal-description" sx={{ mt: 2 }}>
                                {modalErrors.map((error, index) => (
                                    <Typography key={index} variant="body2">
                                        {error}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </Box>
        </ProviderLayout>
    );
}

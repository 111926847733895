import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Checkbox,
    TextField,
    Grid,
    Typography,
    Box,
    Button,
    Menu,
    MenuItem,
    Modal,
    Select,
    Autocomplete,
    InputLabel,
    CircularProgress,
    TablePagination,
    ListItemText,
    FormControlLabel
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextField from '../../../Utils/CustomTextfield';
import { deleteProvider, getAllFacility, getAllProviders, registeration, updateProvider } from '../../../api/action';
import { useDispatch } from 'react-redux';
import CustomSnackbar from '../../../Utils/CustomSnackbar';
import CustomPagination from '../../../Utils/CustomPagination';
import DeleteModal from '../../../Utils/DeleteModal';
import { phoneRegex } from '../../../Utils/Regex';
import * as XLSX from 'xlsx';

const ROWS_PER_PAGE = 10;

const ProviderBody = ({ onEdit, onDelete }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [searchFacilityCode, setSearchFacilityCode] = useState('');
    const [searchFacilityName, setSearchFacilityName] = useState('');
    const [searchFacilityType, setSearchFacilityType] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [newStateName, setNewStateName] = useState('');
    const [newStateCode, setNewStateCode] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [providerType, setProviderType] = useState('Provider Type');
    const [lastName, setLastName] = useState('');
    const [facilityList, setFacilityList] = useState([]);
    const [editFacilityList, setEditFacilityList] = useState([]);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editStateName, setEditStateName] = useState('');
    const [editLastName, setEditLastName] = useState('');
    const [editStateCode, setEditStateCode] = useState('');
    const [editEmail, setEditEmail] = useState('');
    const [editPhone, setEditPhone] = useState('');
    const [editPassword, setEditPassword] = useState('');
    const [userId, setUserId] = useState('');
    const [data, setData] = useState([]);
    const [allFacilties, setAllFacilities] = useState([]);
    const [loading, setLoading] = useState('');
    const [updated, setUpdated] = useState(false);
    const dispatch = useDispatch();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [providerId, setProviderID] = useState('');
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [emailError, setEmailError] = useState('');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [facilityID, setFacilityID] = useState([]);
    const [editFacilityID, setEditFacilityID] = useState([]);
    const [editStatus, setEditStatus] = useState(false);
    const userRole = localStorage.getItem("userRole")


    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllProviders(payload,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setData(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllFacility(payload,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setAllFacilities(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])


    const handleOpenEditModal = (selectedRow) => {
        setEditModalOpen(true);
        // Populate state variables with selected row data
        setEditStateName(selectedRow.First_Name);
        setEditStateCode(selectedRow.providercode);
        setEditEmail(selectedRow.email);
        setEditPhone(selectedRow.Phone_number);
        setEditPassword(selectedRow.password);
        setEditLastName(selectedRow.Last_Name)
        setProviderID(selectedRow.id)
        setEditFacilityList(selectedRow.facility_master)
        setEditStatus(selectedRow.is_active)
        setUserId(selectedRow.user_id)
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };


    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const selectedCodes = data.map((row) => row.providercode);
            setSelectedRows(selectedCodes);
        } else {
            setSelectedRows([]);
        }
        setSelectAll(event.target.checked);
    };

    const handleCheckboxChange = (providercode) => {
        const isSelected = selectedRows.includes(providercode);
        let updatedSelectedRows = [];
        if (isSelected) {
            updatedSelectedRows = selectedRows.filter((code) => code !== providercode);
        } else {
            updatedSelectedRows = [...selectedRows, providercode];
        }
        setSelectedRows(updatedSelectedRows);
        setSelectAll(updatedSelectedRows.length === data.length);
    };

    const handleEditClick = (event, selectedFacility) => {
        setAnchorEl(event.currentTarget);
        setSelectedFacility(selectedFacility);
    };

    const handleEditClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        onDelete(selectedRows);
        setSelectedRows([]);
    };

    const handleAddNew = () => {
        setShowModal(true);
    }

    useEffect(() => {
        setCurrentPage(1)
    }, [searchFacilityCode, searchFacilityName, searchFacilityType])

    const filteredData = data.filter((row) => {
        const matchesFacilityCode = row.providercode.toLowerCase().includes(searchFacilityCode.toLowerCase());
        const matchesFirstName = row.First_Name.toLowerCase().includes(searchFacilityName.toLowerCase());
        const matchesLastName = row.Last_Name.toLowerCase().includes(searchFacilityType.toLowerCase());
        return matchesFacilityCode && matchesFirstName && matchesLastName;
    });

    const handleClearSearch = () => {
        setSearchFacilityCode('');
        setSearchFacilityName('');
        setSearchFacilityType('');
    };

    const handleCloseModal = () => {
        setShowModal(false);
        // Reset input fields when modal is closed
        setNewStateName('');
        setNewStateCode('');
    };

    const handleSaveEditedState = () => {
        // Add your logic to save the new state (e.g., API call, updating state)
        // After saving, close the modal
        setShowModal(false);
    };

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
    };

    const validateEmail = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(value);
        return isValid;
    };

    const handleAddNewProvider = (e) => {
        e.preventDefault();

        if (!email || !password || !newStateName || !newStateCode) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address.');
            setLoading(false);
            setSnackbarMessage('Please enter a valid email address.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (!phoneRegex.test(phone)) {
            setLoading(false);
            setSnackbarMessage('Please enter a valid phone number.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            email,
            password,
            password2: password,
            first_name: newStateName,
            last_name: lastName,
            providercode: newStateCode,
            phone_number: phone,
            user_role: 'Provider',
            facility_master_ids: facilityID,
            is_active:true

        };

        setLoading(true);
        dispatch(
            registeration(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Provider added successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setShowModal(false);
                    setUpdated(prevState => !prevState);
                    setEmail("")
                    setPassword("")
                    setNewStateCode("")
                    setNewStateName("")
                    setPhone("")
                    setLastName("")
                    setFacilityList([])
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || error.response?.data?.msg;
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleUpdateBiller = (e) => {
        e.preventDefault();

        if (!editPassword || !editStateName || !editLastName || !editPhone) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (!phoneRegex.test(editPhone)) {
            setLoading(false);
            setSnackbarMessage('Please enter a valid phone number.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {

            password: editPassword,
            first_name: editStateName,
            last_name: editLastName,
            provider_id: providerId,
            phone_number: editPhone,
            password: editPassword,
            facility_master_ids: editFacilityID,
            is_active:editStatus,
            user_id:userId,

        };

        setLoading(true);
        dispatch(
            updateProvider(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Provider updated successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setEditModalOpen(false);
                    setUpdated(prevState => !prevState);
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleDeleteProvider = (user_id) => {
        const payload = {
            user_id
        };

        setLoading(true);
        dispatch(
            deleteProvider(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Provider deleted successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setEditModalOpen(false);
                    setUpdated(prevState => !prevState);
                    setOpenDeleteModal(false)

                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleDeleteModal = (id) => {
        setOpenDeleteModal(true);
        setProviderID(id)
    };


    // Handle rows per page change
    // Pagination Handlers
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    // Slice the data array to get only the data for the current page
    const paginatedData = filteredData.slice(startIndex, startIndex + ROWS_PER_PAGE);
    // Calculate the end index of the data for the current page
    const endIndex = startIndex + ROWS_PER_PAGE;

    const downloadSelectedRows = () => {
        // Filter rows based on selectedRows array
        const exportData = filteredData
            .filter(row => selectedRows.includes(row.providercode))
            .map(row => ({
                'Provider Code': row.providercode,
                'Provider First Name': row.First_Name,
                'Provider Last Name': row.Last_Name,
                'Email ID': row.email,
                'Phone Number': row.Phone_number,
                'Facilities': row.facility_master.map(item => item.facility_name).join(', '),
                // Add more fields as needed
            }));

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(exportData);
        const columnWidths = [
            { wch: 20 }, // Width of Provider Code column
            { wch: 20 }, // Width of Provider First Name column
            { wch: 20 }, // Width of Provider Last Name column
            { wch: 30 }, // Width of Email ID column
            { wch: 20 }, // Width of Phone Number column
            // Add more width specifications as needed
        ];

        ws['!cols'] = columnWidths;

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

        // Save the workbook as an Excel file
        XLSX.writeFile(wb, 'selected_rows_data.xlsx');
    };



    return (
        <Box sx={{ px: 10, py: 2 }}>
            <DeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                loading={loading}
                setLoading={setLoading}
                handleDeletefunction={handleDeleteProvider}
                id={providerId}
            />
            <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold' }}>Search Provider Master</Typography>
            <Grid container spacing={5} sx={{ py: 2 }}>
                <Grid item md={4} xs={6}>
                    <InputLabel>Provider Code</InputLabel>
                    <CustomTextField
                        value={searchFacilityCode}
                        setValue={setSearchFacilityCode}
                    />
                </Grid>
                <Grid item md={4} xs={6}>
                    <InputLabel>Provider First Name</InputLabel>
                    <CustomTextField
                        value={searchFacilityName}
                        setValue={setSearchFacilityName}
                    />

                </Grid>
                <Grid item md={4} xs={6}>
                    <InputLabel>Provider Last Name</InputLabel>
                    <CustomTextField
                        value={searchFacilityType}
                        setValue={setSearchFacilityType}
                    />


                </Grid>


            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'end', mb: 3 }}>
                <Button
                    className='buttonPadding'
                    variant="contained"
                    onClick={handleClearSearch}
                    sx={{ background: '#0093AF !important', boxShadow: 'none !important', ml: 1 }}
                >
                    Clear
                </Button>
                <Button
                    disabled={userRole !== "Admin"}
                    className='buttonPadding'
                    variant="contained"
                    onClick={handleAddNew}
                    sx={{ background: userRole !== "Admin" ? "#d3d3d3" : '#0093AF !important', boxShadow: 'none !important', mx: 1 }}
                >
                    Add New
                </Button>

                {/* <Button
                    className='buttonPadding'
                    variant="contained"
                    onClick={handleDelete}
                    disabled={selectedRows.length === 0}
                    sx={{ background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', boxShadow: 'none !important', mr: 1 }}
                >
                    Delete
                </Button> */}

                <Button sx={{ float: 'right', boxShadow: 'none !important',  background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', }} variant="contained" onClick={downloadSelectedRows} disabled={selectedRows.length === 0}>Download Excel</Button>

            </Box>
            {loading ? ( // Render CircularProgress while loading is true
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
                    <CircularProgress sx={{color:'#0093AF !important'}} />
                </Box>
            ) : (
                filteredData.length === 0 ? (
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#555', mt: 15, }}>
                        No Data Available
                    </Typography>
                ) : (
                    <>
                        <TableContainer component={Paper} className='scrollBar'>
                            <Table>
                                <TableHead sx={{ backgroundColor: '#0093AF', color: '#fff' }}>
                                    <TableRow>
                                        <TableCell sx={{ py: 0, color: '#fff', display: 'flex', justifyContent: 'left', alignItems: 'center', verticalAlign: 'middle' }}>
                                            <Checkbox
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                                style={{ color: '#fff' }}
                                            />
                                            {/* <Typography>Select All</Typography> */}
                                        </TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Provider Code</TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Provider First Name</TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Provider Last Name</TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Email ID</TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Phone Number</TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Facilities</TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Status</TableCell>
                                        {/* <TableCell sx={{ color: '#fff', py: 0 }}>Password</TableCell> */}
                                        <TableCell sx={{ color: '#fff', py: 0 }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData.slice(startIndex, endIndex).map((row) => (
                                        <TableRow key={row.facilityCode}>
                                            <TableCell sx={{ py: 0.3 }}>
                                                <Checkbox
                                                    checked={selectedRows.includes(row.providercode)}
                                                    onChange={() => handleCheckboxChange(row.providercode)}
                                                    style={{ color: '#0093AF' }}
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row.providercode}</TableCell>
                                            <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row.First_Name}</TableCell>
                                            <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row.Last_Name}</TableCell>
                                            <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row.email}</TableCell>
                                            <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row.Phone_number}</TableCell>
                                            <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>
                                                {row.facility_master.map((item, index) => (
                                                    <Box key={index}>{item?.facility_name}</Box>
                                                ))}
                                            </TableCell>
                                            <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.is_active === true ? "Active" : "Inactive" }</TableCell>

                                            <TableCell sx={{ py: 0.3, whiteSpace: 'nowrap' }}>
                                                <IconButton disabled={userRole !== "Admin"} color="primary" onClick={() => handleOpenEditModal(row)}>
                                                    <EditIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                </IconButton>
                                                <IconButton disabled={userRole !== "Admin"} color="secondary" onClick={() => handleDeleteModal(row.user_id)}>
                                                    <DeleteIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <CustomPagination
                            count={Math.ceil(filteredData.length / ROWS_PER_PAGE)}
                            page={currentPage}
                            onChange={handleChangePage}
                        /></>
                ))}




            {/* Add Modal  */}

            <Modal open={showModal} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 5, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Add Provider Master</Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <InputLabel>Provider Code</InputLabel>
                                <TextField
                                    fullWidth
                                    value={newStateCode}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 4) {
                                            setNewStateCode(e.target.value);
                                        }
                                    }}
                                    inputProps={{ maxLength: 4 }} // Set the maxLength attribute
                                    sx={{ '& input': { height: '22px', padding: '5px 10px', fontSize: "12px" } }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Provider First Name</InputLabel>
                                <CustomTextField
                                    value={newStateName}
                                    setValue={setNewStateName}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Provider Last Name</InputLabel>
                                <CustomTextField
                                    value={lastName}
                                    setValue={setLastName}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Email</InputLabel>
                                <CustomTextField
                                    value={email}
                                    setValue={setEmail}
                                    fullWidth
                                />
                            </Grid>
                            {/* <Grid item md={6}>
                                <InputLabel>Provider Type</InputLabel>
                                <Select
                                    value={providerType}
                                    fullWidth
                                    onChange={(e) => setProviderType(e.target.value)}
                                    sx={{ height: '40px', '& .MuiOutlinedInput-input': { padding: '5px 10px' } }}
                                >

                                    <MenuItem value="Provider Type">Provider Type</MenuItem>
                                    <MenuItem value="Type 1">Type 1</MenuItem>
                                    <MenuItem value="Type 2">Type 2</MenuItem>
                                    <MenuItem value="Type 3">Type 3</MenuItem>
                                </Select>
                            </Grid> */}
                            <Grid item md={6}>
                                <InputLabel>Phone Number</InputLabel>
                                <CustomTextField
                                    value={phone}
                                    setValue={setPhone}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Password</InputLabel>
                                <CustomTextField
                                    value={password}
                                    setValue={setPassword}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={12}>
                                <InputLabel>Facilty</InputLabel>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    fullWidth
                                    value={facilityList}
                                    onChange={(event, newValue) => {
                                        setFacilityList(newValue);
                                        // Log the IDs of selected options
                                        setFacilityID(newValue.map(option => option.id));
                                    }}
                                    sx={{height:'auto !important'}}
                                    options={allFacilties}
                                    getOptionLabel={(option) => `${option.facility_name}`}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <FormControlLabel
                                                control={<Checkbox checked={selected} />}
                                                label={<ListItemText primary={option.facility_name} />}
                                            />
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ '& input': { height: 'auto !important', padding: '5px 10px' } }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={handleAddNewProvider}
                        sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', mt: 3, ml: 'auto' }}
                    >
                        {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Add"}
                    </Button>
                </Box>
            </Modal>


            <Modal open={editModalOpen} onClose={handleCloseEditModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 5, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Edit Provider Master</Typography>
                        <IconButton onClick={handleCloseEditModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <InputLabel>Provider Code</InputLabel>
                                <CustomTextField
                                    value={editStateCode}
                                    setValue={setEditStateCode}
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6}>
                                <InputLabel>Provider First Name</InputLabel>
                                <CustomTextField
                                    value={editStateName}
                                    setValue={setEditStateName}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Provider Last Name</InputLabel>
                                <CustomTextField
                                    value={editLastName}
                                    setValue={setEditLastName}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Email</InputLabel>
                                <CustomTextField
                                    value={editEmail}
                                    setValue={setEditEmail}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            {/* <Grid item md={6}>
                                <InputLabel>Provider Type</InputLabel>
                                <Select
                                    value={providerType}
                                    fullWidth
                                    onChange={(e) => setProviderType(e.target.value)}
                                    sx={{ height: '40px', '& .MuiOutlinedInput-input': { padding: '5px 10px' } }}
                                >
                                    <MenuItem value="Provider Type">Provider Type</MenuItem>
                                    <MenuItem value="Type 1">Type 1</MenuItem>
                                    <MenuItem value="Type 2">Type 2</MenuItem>
                                    <MenuItem value="Type 3">Type 3</MenuItem>
                                </Select>
                            </Grid> */}
                            <Grid item md={6}>
                                <InputLabel>Phone Number</InputLabel>
                                <CustomTextField
                                    value={editPhone}
                                    setValue={setEditPhone}
                                    fullWidth
                                />
                            </Grid>
                            {/* <Grid item md={6}>
                                <InputLabel>Password</InputLabel>
                                <CustomTextField
                                    value={editPassword}
                                    setValue={setEditPassword}
                                    fullWidth
                                />
                            </Grid> */}
                           

                             <Grid item md={6} xs={6}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    fullWidth
                                    value={editStatus}
                                    onChange={(e) => setEditStatus(e.target.value)}
                                    sx={{ height: '32px', padding: '5px 5px', fontSize:'12px' }}
                                >


                                    <MenuItem value={true}>
                                        Active
                                    </MenuItem>

                                    <MenuItem value={false}>
                                        Inactive
                                    </MenuItem>
                                </Select>
                            </Grid>

                            <Grid item md={12}>
                                <InputLabel>Facilty</InputLabel>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    fullWidth
                                    value={editFacilityList}
                                    onChange={(event, newValue) => {
                                        setEditFacilityList(newValue);
                                        // Log the IDs of selected options
                                        setEditFacilityID(newValue.map(option => option.id));
                                    }}
                                    options={allFacilties}
                                    getOptionLabel={(option) => `${option.facility_name}`}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <FormControlLabel
                                                control={<Checkbox checked={selected} />}
                                                label={<ListItemText primary={option.facility_name} />}
                                            />
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ '& input': { height: '0px', padding: '5px 10px' } }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={handleUpdateBiller}
                        sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', mt: 3, ml: 'auto' }}
                    >
                        {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Update"}
                    </Button>
                </Box>
            </Modal>


            <CustomSnackbar
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </Box>
    );
};

export default ProviderBody;

import React, { useState } from 'react';
import { Box, TextField, Button, Paper, Typography, CircularProgress, InputLabel } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../Image/Logo.png';
import CustomSnackbar from '../Utils/CustomSnackbar';
import { useDispatch } from 'react-redux';
import { emailLink, login } from '../api/action';

const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [loading, setLoading] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validateEmail = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(value);

        setEmailError(isValid ? '' : 'Invalid email address');

        return isValid;
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        validateEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    // const handleLoginClick = () => {
    //   if (!validateEmail(email)) {
    //     return;
    //   }

    //   setSnackbarMessage('Logging in successfully');
    //   setSnackbarOpen(true);

    //   switch (userRole) {
    //     case 'Super Admin':
    //       navigate('/dashboard');
    //       break;
    //     case 'Provider':
    //       navigate('/providerdashboard');
    //       break;
    //     default:
    //       navigate('/biller-dashboard');
    //       break;
    //   }
    // };

    const handleLoginClick = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            return;
        }

        if (!email) {
            setLoading(false);
            setSnackbarMessage('Please fill email.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        localStorage.clear();
        sessionStorage.clear();

        const payload = {
            email,
            // isDietition: true
        };

        setLoading(true);
        dispatch(
            emailLink(
                payload,
                (data) => {
                    setLoading(false);
                    console.log(data)
                    if (data?.data?.msg === "Password Reset link send. Please check your Email") {
                        setSnackbarMessage(data?.data?.msg);
                        setSnackbarSeverity("success");
                        setSnackbarOpen(true);
                        setEmail("")
                        setTimeout(() => {
                            // setSnackbarOpen(false);
                            navigate('/')
                        }, 2000);
                    }
                    else {
                        setSnackbarMessage(data?.data?.msg);
                        setSnackbarSeverity("error");
                        setSnackbarOpen(true);
                    }
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);

                }
            )
        );
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const userRole = 'Provider';
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                background: '#0093AF',
                flexDirection: 'column',
                minHieght: '100vh'
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pb: 3, color: '#0093AF', fontWeight: 'bold', verticalAlign: 'middle', }}>
                <img src={Logo} height={"70px"} width={"200px"} style={{ borderRadius: '10px' }} />
            </Box>

            <Paper elevation={3} sx={{ padding: 3, minWidth: 400, py: 5, px: 5 }}>
                {/* <Typography variant='h5' sx={{ fontWeight: 'bold', color: '#0093AF', textAlign: 'center' }}>Billing Portal</Typography> */}
                <InputLabel htmlFor="email">Please enter registered email</InputLabel>
                <TextField
                    // label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    helperText={emailError}
                    sx={{
                        mt: 1,
                        '& fieldset': {
                            borderColor: emailError ? 'red' : '#0093AF',
                        },
                        '&:hover fieldset': {
                            borderColor: emailError ? 'red' : '#0093AF',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: emailError ? 'red' : '#0093AF',
                        },
                    }}
                />

                {/* <TextField
          label="Password"
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
          value={password}
          onChange={handlePasswordChange}
        /> */}


                <Button variant="contained" fullWidth sx={{ background: '#0093AF !important', py: 1.8, mt: 2, textDecoration: 'none !important' }} onClick={handleLoginClick}>
                    <Typography elevation={0} sx={{ color: '#fff', textDecoration: 'none !important' }}>
                        {loading ? (
                            <CircularProgress size={18} sx={{ color: '#fff' }} />
                        ) : (
                            "Send Email"
                        )}
                    </Typography>
                </Button>

                <Typography
                    sx={{ color: '#0093AF', textAlign: 'right', cursor: 'pointer', fontSize: '12px', mt: 3 }}
                    onClick={() => navigate('/')}
                >
                    Back to Login
                </Typography>
            </Paper>

            <CustomSnackbar
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </Box>
    );
};

export default ForgetPassword;

import { deleteAPI, getAPI, postAPI, putAPI } from "../api/api";
import { endpoints } from "../api/endpoints";

// Authorization Apis

export const registeration = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.REGISTERATION,
            payload,
            (response) => {
                console.log('Response For registeration', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While registeration', error);
                errorCallback(error)
            },
        );
    };
};

export const login = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.LOGIN,
            payload,
            (response) => {
                console.log('Response For Login', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While Login', error);
                errorCallback(error)
            },
        );
    };
};

export const emailLink = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.RESENDEMAILLINK,
            payload,
            (response) => {
                console.log('Response For email link', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While email link', error);
                errorCallback(error)
            },
        );
    };
};

export const resetPaswword = (payload, token, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.RESETPASSWORD + token + "/",
            payload,
            (response) => {
                console.log('Response For reset password', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While reset password', error);
                errorCallback(error)
            },
        );
    };
};

// Biller 

export const getAllBillers = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.BILLER,
            payload,
            (response) => {
                console.log('Response For getting billers', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting billers', error);
                errorCallback(error)
            },
        );
    };
};

export const updateBiller = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.BILLER,
            payload,
            (response) => {
                console.log('Response For updating registeration', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating registeration', error);
                errorCallback(error)
            },
        );
    };
};

export const deleteBiller = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        deleteAPI(
            endpoints.BILLER,
            payload,
            (response) => {
                console.log('Response For getting billers', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting billers', error);
                errorCallback(error)
            },
        );
    };
};

// Provider 

export const updateProvider = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.PROVIDER,
            payload,
            (response) => {
                console.log('Response For updating providers', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating providers', error);
                errorCallback(error)
            },
        );
    };
};

export const getAllProviders = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.PROVIDER,
            payload,
            (response) => {
                console.log('Response For getting providers', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting providers', error);
                errorCallback(error)
            },
        );
    };
};

export const providerListByFacility = (payload, id, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.providerListByFacility + `?facility_ids=${id}`,
            payload,
            (response) => {
                console.log('Response For getting providers', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting providers', error);
                errorCallback(error)
            },
        );
    };
};

export const deleteProvider = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        deleteAPI(
            endpoints.PROVIDER,
            payload,
            (response) => {
                console.log('Response For deleting providers', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While deleting providers', error);
                errorCallback(error)
            },
        );
    };
};

// Admin 

export const getAllAdmins = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.ADMIN,
            payload,
            (response) => {
                console.log('Response For getting providers', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting providers', error);
                errorCallback(error)
            },
        );
    };
};

// export const getAllGraph = (payload, callBack, errorCallback) => {
//     return (dispatch) => {
//         getAPI(
//             endpoints.adminGraph,
//             payload,
//             (response) => {
//                 console.log('Response For getting graph data', response);
//                 callBack(response);
//             },
//             (error) => {
//                 console.log('Error While getting graph data', error);
//                 errorCallback(error)
//             },
//         );
//     };
// };

export const getAllGraph = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.adminGraph,
            payload,
            (response) => {
                console.log('Response For getting graph data', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting graph data', error);
                errorCallback(error)
            },
        );
    };
};

// export const getProviderGraph = (payload, id, callBack, errorCallback) => {
//     return (dispatch) => {
//         getAPI(
//             endpoints.providerGraph + `?user_id=${id}`,
//             payload,
//             (response) => {
//                 console.log('Response For getting graph data', response);
//                 callBack(response);
//             },
//             (error) => {
//                 console.log('Error While getting graph data', error);
//                 errorCallback(error)
//             },
//         );
//     };
// };

export const getProviderGraph = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.providerGraph ,
            payload,
            (response) => {
                console.log('Response For getting graph data', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting graph data', error);
                errorCallback(error)
            },
        );
    };
};

export const getPatientSeenGraph = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.patientSeen,
            payload,
            (response) => {
                console.log('Response For getting graph data', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting graph data', error);
                errorCallback(error)
            },
        );
    };
};

export const updateAdmin = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.ADMIN,
            payload,
            (response) => {
                console.log('Response For updating provider', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating provider', error);
                errorCallback(error)
            },
        );
    };
};

export const deleteAdmin = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        deleteAPI(
            endpoints.ADMIN,
            payload,
            (response) => {
                console.log('Response For deleting admin', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While deleting admin', error);
                errorCallback(error)
            },
        );
    };
};

// State Master  

export const getAllStateMasters = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.STATEMASTER,
            payload,
            (response) => {
                console.log('Response For getting state masters', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting state masters', error);
                errorCallback(error)
            },
        );
    };
};

export const getEmployeeProviderList = (payload, id, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.employeeProviderList + `?admin_user_id=${id}`,
            payload,
            (response) => {
                console.log('Response For getting graph data', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting graph data', error);
                errorCallback(error)
            },
        );
    };
};

export const addStateMaster = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.STATEMASTER,
            payload,
            (response) => {
                console.log('Response For updating state master', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating state master', error);
                errorCallback(error)
            },
        );
    };
};

export const updateStateMaster = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.STATEMASTER,
            payload,
            (response) => {
                console.log('Response For updating state master', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating state master', error);
                errorCallback(error)
            },
        );
    };
};

export const deleteStateMaster = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        deleteAPI(
            endpoints.STATEMASTER,
            payload,
            (response) => {
                console.log('Response For deleting state master', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While deleting state master', error);
                errorCallback(error)
            },
        );
    };
};

// Facility Type Master 

export const getAllFacilityTypes = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.FACILITYTYPE,
            payload,
            (response) => {
                console.log('Response For getting Facility Type masters', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting Facility Type masters', error);
                errorCallback(error)
            },
        );
    };
};

export const addFacilityType = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.FACILITYTYPE,
            payload,
            (response) => {
                console.log('Response For updating Facility Type master', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating Facility Type master', error);
                errorCallback(error)
            },
        );
    };
};

export const updateFacilityType = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.FACILITYTYPE,
            payload,
            (response) => {
                console.log('Response For updating Facility Type master', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating Facility Type master', error);
                errorCallback(error)
            },
        );
    };
};

export const deleteFacilityType = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        deleteAPI(
            endpoints.FACILITYTYPE,
            payload,
            (response) => {
                console.log('Response For deleting Facility Type master', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While deleting Facility Type master', error);
                errorCallback(error)
            },
        );
    };
};

// Visit Reason Master 

export const getAllVisitReasons = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.VISITREASON,
            payload,
            (response) => {
                console.log('Response For getting visit reason masters', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting visit reason masters', error);
                errorCallback(error)
            },
        );
    };
};

export const addVisitReason = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.VISITREASON,
            payload,
            (response) => {
                console.log('Response For updating visit reason master', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating visit reason master', error);
                errorCallback(error)
            },
        );
    };
};

export const updateVisitReason = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.VISITREASON,
            payload,
            (response) => {
                console.log('Response For updating visit reason master', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating visit reason master', error);
                errorCallback(error)
            },
        );
    };
};

export const deleteVisitReason = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        deleteAPI(
            endpoints.VISITREASON,
            payload,
            (response) => {
                console.log('Response For deleting visit reason master', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While deleting visit reason master', error);
                errorCallback(error)
            },
        );
    };
};

// Facility/Clinics 

export const getAllFacility = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.FACILITY,
            payload,
            (response) => {
                console.log('Response For getting Facility ', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting Facility ', error);
                errorCallback(error)
            },
        );
    };
};

export const addFacility = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.FACILITY,
            payload,
            (response) => {
                console.log('Response For updating Facility', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating Facility', error);
                errorCallback(error)
            },
        );
    };
};

export const updateFacility = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.FACILITY,
            payload,
            (response) => {
                console.log('Response For updating Facility', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating Facility', error);
                errorCallback(error)
            },
        );
    };
};

export const deleteFacility = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        deleteAPI(
            endpoints.FACILITY,
            payload,
            (response) => {
                console.log('Response For deleting Facility', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While deleting Facility', error);
                errorCallback(error)
            },
        );
    };
};


export const getTimeSheetByAdmin = (payload, page, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.adminTimeSheet + `?page=${page}`,
            payload,
            (response) => {
                console.log('Response For getting admin time sheet', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting admin time sheet', error);
                errorCallback(error)
            },
        );
    };
};

export const updateTimeSheetByAdmin = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.adminTimeSheet ,
            payload,
            (response) => {
                console.log('Response For updating admin time sheet', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating admin time sheet', error);
                errorCallback(error)
            },
        );
    };
};

export const updateMultipleTimeSheetByAdmin = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.updateMultipleTimesheet ,
            payload,
            (response) => {
                console.log('Response For updating admin time sheet', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating admin time sheet', error);
                errorCallback(error)
            },
        );
    };
};

export const addBillerProvider = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.billerProviderMapping,
            payload,
            (response) => {
                console.log('Response For adding provider and biller realtion', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While adding provider and biller realtio', error);
                errorCallback(error)
            },
        );
    };
};

export const getBillerProvider = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.billerProviderMapping,
            payload,
            (response) => {
                console.log('Response For getting provider and biller realtion', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting provider and biller realtio', error);
                errorCallback(error)
            },
        );
    };
};


export const deleteBillerProvider = (payload, callBack, errorCallback) => {
    console.log(payload)
    return (dispatch) => {
        deleteAPI(
            endpoints.billerProviderMapping,
            payload,
            (response) => {
                console.log('Response For deleting provider and biller realtion', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While deleting provider and biller realtio', error);
                errorCallback(error)
            },
        );
    };
};

export const updateBillerProvider = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.billerProviderMapping,
            payload,
            (response) => {
                console.log('Response For updating provider and biller realtion', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating provider and biller realtio', error);
                errorCallback(error)
            },
        );
    };
};


export const getSuperBillByAdmin = (payload,page,  callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.superBillAdmin + `?page=${page}`  ,
            payload,
            (response) => {
                console.log('Response For getting admin super bill', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting admin super bill', error);
                errorCallback(error)
            },
        );
    };
};

export const deleteSuperBill = (payload,  callBack, errorCallback) => {
    return (dispatch) => {
        deleteAPI(
            endpoints.superBill  ,
            payload,
            (response) => {
                console.log('Response For deleting  super bill', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While delete  super bill', error);
                errorCallback(error)
            },
        );
    };
};


// Provider 

// Timesheet 

export const addTimeSheetByProvider = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.providerTimeSheet,
            payload,
            (response) => {
                console.log('Response For adding provider time sheet', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While adding provider time sheet', error);
                errorCallback(error)
            },
        );
    };
};

export const updateTimeSheetByProvider = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.providerTimeSheet ,
            payload,
            (response) => {
                console.log('Response For getting provider time sheet', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting provider time sheet', error);
                errorCallback(error)
            },
        );
    };
};

export const deleteTimeSheetByProvider = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        deleteAPI(
            endpoints.providerTimeSheet ,
            payload,
            (response) => {
                console.log('Response For getting provider time sheet', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting provider time sheet', error);
                errorCallback(error)
            },
        );
    };
};

export const getTimeSheetByProvider = (payload,userId, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.providerTimeSheet + `?user_id=${userId}`,
            payload,
            (response) => {
                console.log('Response For getting provider time sheet', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting provider time sheet', error);
                errorCallback(error)
            },
        );
    };
};

export const getFacilityByProviderId = (payload, userId, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.getProviderFaciltyById + `?user_id=${userId}`,
            payload,
            (response) => {
                console.log('Response For getting Facility by provider', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting Facility by provider', error);
                errorCallback(error)
            },
        );
    };
};

export const getFacilityTypeByProviderId = (payload, userId, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.getProviderFaciltyTypeById + `?facility_id=${userId}`,
            payload,
            (response) => {
                console.log('Response For getting Facility Type by provider', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting Facility Type by provider', error);
                errorCallback(error)
            },
        );
    };
};

export const getPatientByProviderId = (payload, userId, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.providerPatients + `?user_id=${userId}`,
            payload,
            (response) => {
                console.log('Response For getting Patients by provider', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting Patients by provider', error);
                errorCallback(error)
            },
        );
    };
};

export const addSuperBillByProvider = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.superBill,
            payload,
            (response) => {
                console.log('Response For adding provider super bill', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While adding provider super bill', error);
                errorCallback(error)
            },
        );
    };
};

export const getSuperBillByProvider = (payload, userId, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.superBill  + `?user_id=${userId}`,
            payload,
            (response) => {
                console.log('Response For adding provider super bill', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While adding provider super bill', error);
                errorCallback(error)
            },
        );
    };
};

export const updateSuperBillByProvider = (payload,  callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.superBill,
            payload,
            (response) => {
                console.log('Response For adding provider super bill', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While adding provider super bill', error);
                errorCallback(error)
            },
        );
    };
};

export const submitForAuthorization = (payload,  callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.superBillSubmit,
            payload,
            (response) => {
                console.log('Response For adding provider super bill', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While adding provider super bill', error);
                errorCallback(error)
            },
        );
    };
};

export const getReportByProvider = (payload,  page,  callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.superBillProvider  + `?page=${page}`  ,
            payload,
            (response) => {
                console.log('Response For getting provider super bill', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting provider super bill', error);
                errorCallback(error)
            },
        );
    };
};


// Billers 

export const getReportByBiller = (payload,  userId,  callBack, errorCallback) => {
    console.log(userId)
    return (dispatch) => {
        getAPI(
            endpoints.superBillBiller  + `?provider_id=${userId}` ,
            payload,
            (response) => {
                console.log('Response For getting provider super bill', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting provider super bill', error);
                errorCallback(error)
            },
        );
    };
};


export const updateReportByBillerId = (payload,  callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.superBillBiller  ,
            payload,
            (response) => {
                console.log('Response For updating biller super bill', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating biller super bill', error);
                errorCallback(error)
            },
        );
    };
};

export const updateMultipleReportByBillerId = (payload,  callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.updateMultipleReportBybiller  ,
            payload,
            (response) => {
                console.log('Response For updating biller reports', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating biller reports', error);
                errorCallback(error)
            },
        );
    };
};

export const getProvidersByBillerId = (payload,  userId,  callBack, errorCallback) => {
    console.log(userId)
    return (dispatch) => {
        getAPI(
            endpoints.providersbyBillerId  + `?user_id=${userId}` ,
            payload,
            (response) => {
                console.log('Response For getting provider super bill', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting provider super bill', error);
                errorCallback(error)
            },
        );
    };
};

export const uploadExcel = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.excelUpload,
            payload,
            (response) => {
                console.log('Response For uploading', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While uploading', error);
                errorCallback(error)
            },
        );
    };
};


// Employee 
export const getEmployee = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.employee,
            payload,
            (response) => {
                console.log('Response For getting employee', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting employee', error);
                errorCallback(error)
            },
        );
    };
};

export const deleteEmployee = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        deleteAPI(
            endpoints.employee,
            payload,
            (response) => {
                console.log('Response For deleting employee', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While deleting employee', error);
                errorCallback(error)
            },
        );
    };
};

export const updateEmployee = (payload, billerId, callBack, errorCallback) => {
    return (dispatch) => {
        putAPI(
            endpoints.employee + `?user_id=${billerId}` ,
            payload,
            (response) => {
                console.log('Response For updating registeration', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While updating registeration', error);
                errorCallback(error)
            },
        );
    };
};

export const deleteSuperBillByBiller = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        deleteAPI(
            endpoints.deleteSuperbill,
            payload,
            (response) => {
                console.log('Response For deleting superbill', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting deleting superbill', error);
                errorCallback(error)
            },
        );
    };
};


export const providermonthlyCount = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.providerCountGraph,
            payload,
            (response) => {
                console.log('Response For uploading', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While uploading', error);
                errorCallback(error)
            },
        );
    };
};

export const getSelectedCopyData = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.copyData,
            payload,
            (response) => {
                console.log('Response For getting copy data', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While copy data', error);
                errorCallback(error)
            },
        );
    };
};

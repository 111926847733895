import React, { useEffect, useState, useRef } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Checkbox,
    TextField,
    Grid,
    Typography,
    Box,
    Button,
    Menu,
    MenuItem,
    Modal,
    Select,
    Autocomplete,
    InputLabel,
    CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextField from '../../../Utils/CustomTextfield';
import { useDispatch } from 'react-redux';
import { getAllFacility, getAllFacilityTypes, getAllProviders, getEmployeeProviderList, getTimeSheetByAdmin, getTimeSheetByProvider, updateMultipleTimeSheetByAdmin, updateTimeSheetByAdmin } from '../../../api/action';
import CustomSnackbar from '../../../Utils/CustomSnackbar';
import CustomPagination from '../../../Utils/CustomPagination';
import * as XLSX from 'xlsx';
import convertTo12HourFormat from '../../../Utils/HelpingFunction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TimeSheetBody = ({ onEdit, onDelete }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [status, setStatus] = useState('');
    const [jobType, setJobType] = useState('');
    const [facilityList, setFacilityList] = useState(null);
    const [providerList, setProviderList] = useState(null);
    const [timeSheetData, setTimeSheetData] = useState([]);
    const [loading, setLoading] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [providers, setProviders] = useState([]);
    const dispatch = useDispatch();
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [facilities, setAllFacilities] = useState([]);
    const [date, setDate] = useState('');
    const [provider, setProvider] = useState('');
    const [facilityName, setFacilityName] = useState('');
    const [editJobType, setEditJobType] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [lunch, setLunch] = useState('');
    const [totalHours, setTotalHours] = useState('');
    const [patientSeen, setPatientSeen] = useState('');
    const [data, setData] = useState([]);
    const [comment, setComment] = useState('');
    const [editStatus, setEditStatus] = useState('');
    const [id, setId] = useState('');
    const [updated, setUpdated] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [filteredData, setFilteredData] = useState([]);
    const [totalPageHours, setTotalPageHours] = useState(0);
    const [totalSelectedHours, setTotalSelectedHours] = useState('');
    const [counts, setCounts] = useState("");
    const [search, setSearch] = useState(false);
    const [previousPayload, setPreviousPayload] = useState(null)
    const ROWS_PER_PAGE = 50;
    const prevMyStateRef = useRef();

    // useEffect(() => {
    //     setLoading(true)
    //     const payload = null;
    //     dispatch(
    //         getTimeSheetByAdmin(payload,
    //             (data) => {
    //                 setLoading(false);
    //                 setTimeSheetData(data?.data?.Data)
    //                 // setFilteredData(data?.data?.Data)
    //             },
    //             (error) => {
    //                 console.log(error)
    //                 setLoading(false)
    //             }
    //         )
    //     );

    // }, [updated])

    const formatDate = (date) => {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleSearch = () => {
        setFilteredData([])

        const baseProviderName = providerList ? providerList.split(' ')[0] : null;
        if (prevMyStateRef.current !== undefined && prevMyStateRef.current === baseProviderName) {
            setCurrentPage(1);
        }
        if (!jobType && !baseProviderName && !facilityList && !status && !startDate && !endDate && search === true) {
            setSnackbarMessage("Please fill the above field");
            setSnackbarOpen(true);
            setSnackbarSeverity("error");
        } else {
            setLoading(true)
            const payload = {
                "designation": jobType,
                "name": baseProviderName,
                "facility": facilityList?.id,
                "status": status,
                "date_from": startDate ? formatDate(startDate) : null,
                "date_to": endDate ? formatDate(endDate) : null,
                "download_excel": false
            }

            const isPayloadChanged = (currentPayload, previousPayload) => {
                return JSON.stringify(currentPayload) !== JSON.stringify(previousPayload);
            };
            
            // Determine the current page
            const updatedPage = isPayloadChanged(payload, previousPayload) ? 1 : currentPage;

            dispatch(
                getTimeSheetByAdmin(payload, updatedPage,
                    (data) => {
                        setLoading(false);
                        setFilteredData(search === true ? data?.data?.results : []);
                        setCounts(data?.data?.count)
                        setSelectAll(false)
                        setSelectedRows([])
                    },
                    (error) => {
                        console.log(error)
                        setLoading(false)
                    }
                )
            );
        }



    };

    useEffect(() => {
        handleSearch()
        setSearch(true)
    }, [updated, currentPage])

    // useEffect(() => {
    //     setLoading(true);
    //     const payload = null;
    //     dispatch(
    //         getAllProviders(
    //             payload,
    //             (data) => {
    //                 console.log(data?.data?.Data);
    //                 setLoading(false);
    //                 const formattedProviderList = data?.data?.Data.map(provider => ({
    //                     title: provider.First_Name + " " + provider.Last_Name
    //                 }));
    //                 setProviders(formattedProviderList);
    //             },
    //             (error) => {
    //                 console.log(error);
    //                 setLoading(false);
    //             }
    //         )
    //     );
    // }, []);



    useEffect(() => {
        // setLoading(true);
        const payload = null;
        const id = localStorage.getItem("userId");
        dispatch(
            getEmployeeProviderList(
                payload, id,
                (data) => {
                    setLoading(false);

                    let combinedNames = [];

                    // Extracting names from providers
                    data?.data.providers.forEach(provider => {
                        if (jobType === "" || jobType === "Provider") {
                            combinedNames.push(provider.Full_Name + ' (Provider)');
                        }
                    });

                    // Extracting names from billers
                    data?.data.billers.forEach(biller => {
                        if (jobType === "" || jobType === "Biller") {
                            combinedNames.push(biller.Full_Name + ' (Biller)');
                        }
                    });

                    // Extracting names from admin employees
                    data?.data.admin_employees.forEach(employee => {
                        if (jobType === "" || jobType === "Employee_Office_Work") {
                            combinedNames.push(employee.Full_Name + ' (Employee)');
                        }
                    });

                    data?.data.consultant.forEach(consultant => {
                        if (jobType === "" || jobType === "Consultant") {
                            combinedNames.push(consultant.Full_Name + ' (Consultant)');
                        }
                    });

                    setProviders(combinedNames);
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            )
        );
    }, [dispatch, jobType]);



    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllFacility(payload,
                (data) => {
                    setLoading(false);
                    const formattedFacilityList = data?.data?.Data.map(facility => ({
                        title: `${facility.facility_name}`
                    }));
                    setAllFacilities(formattedFacilityList)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [])

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const selectedCodes = filteredData.map((row) => row.id);
            setSelectedRows(selectedCodes);
        } else {
            setSelectedRows([]);
        }
        setSelectAll(event.target.checked);
    };

    const handleCheckboxChange = (id) => {
        const isSelected = selectedRows.includes(id);
        let updatedSelectedRows = [];
        if (isSelected) {
            updatedSelectedRows = selectedRows.filter((code) => code !== id);
        } else {
            updatedSelectedRows = [...selectedRows, id];
        }
        setSelectedRows(updatedSelectedRows);
        setSelectAll(updatedSelectedRows?.length === data?.length);
    };

    const handleEditClick = (event, selectedFacility) => {
        // setAnchorEl(event.currentTarget);
        setSelectedFacility(selectedFacility);
    };

    const handleEditClose = () => {
        // setAnchorEl(null);
    };

    const handleEdit = (newStatus) => {
        if (selectedFacility) {
            const updatedFacility = {
                ...selectedFacility,
                status: newStatus,
            };

            // Update the status in the data array
            const updatedData = data.map((facility) =>
                facility.facilityCode === selectedFacility.facilityCode ? updatedFacility : facility
            );

            // Trigger a re-render by updating the data state
            setData(updatedData);
            onEdit(updatedFacility);
            // setAnchorEl(null);
        }
    };



    const handleDelete = () => {
        onDelete(selectedRows);
        setSelectedRows([]);
    };

    const handleEditModal = (row) => {
        setId(row?.id)
        setShowModal(true);
        setDate(row?.date)
        setProvider(row?.user)
        setFacilityName(row?.facility)
        setEditJobType(row?.type_of_job)
        setStartTime(row?.start_time)
        setEndTime(row?.end_time)
        setLunch(row?.lunch_break)
        setTotalHours(row?.total_hours)
        setPatientSeen(row?.patient_seen)
        setComment(row?.comment)
        setEditStatus(row?.status)
    }

    // useEffect(() => {
    //     setCurrentPage(1)
    // }, [providerList, jobType, status, facilityList])


    // const filteredData = timeSheetData?.filter((row) => {
    //     console.log("row", row)
    //     const matchesProvider = providerList ? providerList === row.user : true;
    //     const matchesJobType = jobType === '' || (row?.user_role?.toLowerCase?.() === jobType.toLowerCase());
    //     const matchesStatus = status === '' || (row?.status?.toLowerCase?.() === status.toLowerCase());
    //     const matchesFacilityName = facilityList ? facilityList.title === row.facility : true;
    //     const date = new Date(row.date);
    //     const matchesDateRange = (!startDate && !endDate) ||
    //         (startDate && endDate && date >= startDate && date <= endDate);

    //     return matchesProvider && matchesJobType && matchesStatus && matchesFacilityName && matchesDateRange;
    // });
    // const handleSearch = () => {
    //     const filteredData = timeSheetData?.filter((row) => {

    //         // Extract the base name from providerList (remove the suffix)
    //         const baseProviderName = providerList ? providerList.split(' ')[0] : null;

    //         // Function to check if row.user matches the base name
    //         const matchesProvider = providerList ? row.user.startsWith(baseProviderName) : true;
    //         const matchesJobType = jobType === '' || (row?.user_role?.toLowerCase?.() === jobType.toLowerCase());
    //         const matchesStatus = status === '' || (row?.status?.toLowerCase?.() === status.toLowerCase());
    //         const matchesFacilityName = facilityList ? facilityList.title === row.facility : true;
    //         const date = new Date(row.date);
    //         const matchesDateRange = (!startDate && !endDate) ||
    //             (startDate && endDate && date >= startDate && date <= endDate);

    //         return matchesProvider && matchesJobType && matchesStatus && matchesFacilityName && matchesDateRange;
    //     });
    //     setFilteredData(filteredData);
    //     setCurrentPage(1);
    // };


    const handleClearSearch = () => {
        setDateRange([null, null])
        setProviderList(null);
        setFacilityList(null);
        setJobType("");
        setStatus("")
        setFilteredData([])
    };


    const handleCloseModal = () => {
        // setShowModal(false);
        // setNewStateName('');
        // setNewStateCode('');
    };

    const handleSaveNewState = () => {
        const payload = {
            timesheet_id: id,
            status: editStatus,
            comment: comment
        };


        setLoading(true);
        dispatch(
            updateTimeSheetByAdmin(
                payload,
                (data) => {
                    setLoading(false);
                    setUpdated(prevState => !prevState);
                    setSnackbarMessage("Time Sheet updated successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
        setShowModal(false);
    };

    const handleMultipleTimesheet = (status) => {
        const payload = {
            timesheet_ids: selectedRows,
            status: status,
        };


        setLoading(true);
        dispatch(
            updateMultipleTimeSheetByAdmin(
                payload,
                (data) => {
                    setLoading(false);
                    setUpdated(prevState => !prevState);
                    setSnackbarMessage("Time Sheet updated successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    // handleSearch()
                    setSelectedRows([])
                    // setFilteredData([])
                    setSelectAll(false)

                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
        setShowModal(false);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleCloseEditModal = () => {
        // setEditModalOpen(false);
    };


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    // Handle rows per page change
    // Pagination Handlers
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    // Slice the data array to get only the data for the current page
    // const paginatedData = filteredData.slice(startIndex, startIndex + ROWS_PER_PAGE);
    // Calculate the end index of the data for the current page
    const endIndex = startIndex + ROWS_PER_PAGE;

    // const downloadSelectedRows = () => {
    //     // Filter rows based on selectedRows array
    //     const exportData = filteredData
    //         .filter(row => selectedRows.includes(row.id))
    //         .map(row => ({
    //             'Date': row.date,
    //             'Provider': row.user,
    //             'Designation': row?.user_role === "Employee_Office_Work" || row?.user_role === "Employee_Admin_Work" ? "Employee" : row?.user_role,
    //             'Facility Name': row.facility,
    //             'Type of Job': row.type_of_job,
    //             'Start Time': row.start_time,
    //             'End Time': row.end_time,
    //             'Lunch Break': row.lunch_break,
    //             'Total Hours': row.total_hours.replace(/-\d+ day[s]?, /, ""),
    //             'Patient Seen': row.patient_seen,
    //             'Comment': row.comment,
    //             'Status': row.status,
    //             'Remarks': row.remarks
    //         }));

    //     const totalRow = {
    //         'Date': "",
    //         'Provider': "",
    //         'Designation': "",
    //         'Facility Name': "",
    //         'Type of Job': "",
    //         'Start Time': "",
    //         'End Time': "",
    //         'Lunch Break': "",
    //         'Total Hours': `Sum of Hours:${totalSelectedHours}`,
    //         'Patient Seen': "",
    //         'Comment': "",
    //         'Status': "",
    //         'Remarks': "",
    //     };

    //     exportData.push(totalRow);

    //     // Create a new workbook
    //     const wb = XLSX.utils.book_new();

    //     // Convert data to worksheet
    //     const ws = XLSX.utils.json_to_sheet(exportData);
    //     const columnWidths = [
    //         { wch: 15 }, // Width of Date column
    //         { wch: 20 }, // Width of Provider column
    //         { wch: 20 }, // Width of Facility Name column
    //         { wch: 20 }, // Width of Type of Job column
    //         { wch: 15 }, // Width of Start Time column
    //         { wch: 15 }, // Width of End Time column
    //         { wch: 15 }, // Width of Lunch Break column
    //         { wch: 40 }, // Width of Total Hours column
    //         { wch: 15 }, // Width of Patient Seen column
    //         { wch: 20 }, // Width of Comment column
    //         { wch: 15 },  // Width of Status column
    //         { wch: 40 }  // Width of Status column
    //     ];

    //     ws['!cols'] = columnWidths;

    //     // Add worksheet to workbook
    //     XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

    //     // Save the workbook as an Excel file
    //     XLSX.writeFile(wb, 'Timesheet.xlsx');
    //     setSelectAll(false)
    //     setSelectedRows([])
    // };

    const sumTotalHours = (timeSheetData) => {
        let totalHours = 0;
        let totalMinutes = 0;
        let totalSeconds = 0;

        // Iterate over each row in timeSheetData to parse and sum up hours, minutes, and seconds
        timeSheetData?.forEach(row => {
            const { hours, minutes, seconds } = parseTimeString(row.total_hours);
            totalHours += hours;
            totalMinutes += minutes;
            totalSeconds += seconds;
        });

        // Handle overflow of seconds and minutes
        totalMinutes += Math.floor(totalSeconds / 60);  // Add overflow seconds to minutes
        totalSeconds = totalSeconds % 60;               // Remainder of seconds
        totalHours += Math.floor(totalMinutes / 60);    // Add overflow minutes to hours
        totalMinutes = totalMinutes % 60;               // Remainder of minutes

        // Format the total time as a string
        return `${totalHours.toString().padStart(2, '0')} Hours ${totalMinutes.toString().padStart(2, '0')} Minutes`;
        // Optionally, you can return it in HH:MM:SS format:
        // return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}:${totalSeconds.toString().padStart(2, '0')}`;
    };

    const downloadSelectedRows = () => {
        if (selectAll === true) {
            const baseProviderName = providerList ? providerList.split(' ')[0] : null;

            setLoading(true)
            const payload = {
                "designation": jobType,
                "name": baseProviderName,
                "facility": facilityList?.id,
                "status": status,
                "date_from": startDate ? formatDate(startDate) : null,
                "date_to": endDate ? formatDate(endDate) : null,
                "download_excel": selectAll
            }

            dispatch(
                getTimeSheetByAdmin(payload, currentPage,
                    (data) => {
                        setLoading(false);
                        setData(data?.data?.Data);
                        // const pageData = data?.data?.Data?.filter(row => selectedRows.includes(row.id));
                        // const totalHours = sumTotalHours(pageData);


                        const pageData = data?.data?.Data;
                        const totalHours = sumTotalHours(pageData);
                        console.log(totalHours, "totalSelectedHours")
                        // setTotalPageHours(totalHours);
                        setTotalSelectedHours(totalHours);


                        const exportData = data?.data?.Data.map(row => ({
                            'Date': row.date,
                            'Provider': row.user,
                            'Designation': row?.user_role === "Employee_Office_Work" || row?.user_role === "Employee_Admin_Work" ? "Employee" : row?.user_role,
                            'Facility Name': row.facility,
                            'Type of Job': row.type_of_job,
                            'Start Time': row.start_time,
                            'End Time': row.end_time,
                            'Lunch Break': row.lunch_break,
                            'Total Hours': row.total_hours.replace(/-\d+ day[s]?, /, ""),
                            'Patient Seen': row.patient_seen,
                            'Comment': row.comment,
                            'Status': row.status,
                            'Remarks': row.remarks
                        }));

                        const totalRow = {
                            'Date': "",
                            'Provider': "",
                            'Designation': "",
                            'Facility Name': "",
                            'Type of Job': "",
                            'Start Time': "",
                            'End Time': "",
                            'Lunch Break': "",
                            'Total Hours': `Sum of Hours:${totalHours}`,
                            'Patient Seen': "",
                            'Comment': "",
                            'Status': "",
                            'Remarks': "",
                        };

                        exportData.push(totalRow);

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();

                        // Convert data to worksheet
                        const ws = XLSX.utils.json_to_sheet(exportData);
                        const columnWidths = [
                            { wch: 15 }, // Width of Date column
                            { wch: 20 }, // Width of Provider column
                            { wch: 20 }, // Width of Facility Name column
                            { wch: 20 }, // Width of Type of Job column
                            { wch: 15 }, // Width of Start Time column
                            { wch: 15 }, // Width of End Time column
                            { wch: 15 }, // Width of Lunch Break column
                            { wch: 40 }, // Width of Total Hours column
                            { wch: 15 }, // Width of Patient Seen column
                            { wch: 20 }, // Width of Comment column
                            { wch: 15 },  // Width of Status column
                            { wch: 40 }  // Width of Status column
                        ];

                        ws['!cols'] = columnWidths;

                        // Add worksheet to workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

                        // Save the workbook as an Excel file
                        XLSX.writeFile(wb, 'Timesheet.xlsx');
                        setSelectAll(false)
                        setSelectedRows([])

                    },
                    (error) => {
                        console.log(error)
                        setLoading(false)
                    }
                )
            );
        } else {
            const exportData = filteredData
                .filter(row => selectedRows.includes(row.id))
                .map(row => ({
                    'Date': row.date,
                    'Provider': row.user,
                    'Designation': row?.user_role === "Employee_Office_Work" || row?.user_role === "Employee_Admin_Work" ? "Employee" : row?.user_role,
                    'Facility Name': row.facility,
                    'Type of Job': row.type_of_job,
                    'Start Time': row.start_time,
                    'End Time': row.end_time,
                    'Lunch Break': row.lunch_break,
                    'Total Hours': row.total_hours.replace(/-\d+ day[s]?, /, ""),
                    'Patient Seen': row.patient_seen,
                    'Comment': row.comment,
                    'Status': row.status,
                    'Remarks': row.remarks
                }));

            const totalRow = {
                'Date': "",
                'Provider': "",
                'Designation': "",
                'Facility Name': "",
                'Type of Job': "",
                'Start Time': "",
                'End Time': "",
                'Lunch Break': "",
                'Total Hours': `Sum of Hours:${totalSelectedHours}`,
                'Patient Seen': "",
                'Comment': "",
                'Status': "",
                'Remarks': "",
            };

            exportData.push(totalRow);

            // Create a new workbook
            const wb = XLSX.utils.book_new();

            // Convert data to worksheet
            const ws = XLSX.utils.json_to_sheet(exportData);
            const columnWidths = [
                { wch: 15 }, // Width of Date column
                { wch: 20 }, // Width of Provider column
                { wch: 20 }, // Width of Facility Name column
                { wch: 20 }, // Width of Type of Job column
                { wch: 15 }, // Width of Start Time column
                { wch: 15 }, // Width of End Time column
                { wch: 15 }, // Width of Lunch Break column
                { wch: 40 }, // Width of Total Hours column
                { wch: 15 }, // Width of Patient Seen column
                { wch: 20 }, // Width of Comment column
                { wch: 15 },  // Width of Status column
                { wch: 40 }  // Width of Status column
            ];

            ws['!cols'] = columnWidths;

            // Add worksheet to workbook
            XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

            // Save the workbook as an Excel file
            XLSX.writeFile(wb, 'Timesheet.xlsx');
            setSelectAll(false)
            setSelectedRows([])

        };
    }

    function getUserRoleDisplay(userRole) {
        return userRole === "Employee_Office_Work" || userRole === "Employee_Admin_Work" ? "Employee" : userRole;
    }

    const parseTimeString = (timeString) => {
        // Regular expression to match and remove "-N day(s), "
        const cleanedTimeString = timeString.replace(/-\d+ day[s]?, /, '');

        const [hours, minutes, seconds] = cleanedTimeString.split(':').map(Number);
        return {
            hours: hours || 0,
            minutes: minutes || 0,
            seconds: seconds || 0
        };
    };



    useEffect(() => {
        if (selectAll === true) {
            const pageData = data?.filter(row => selectedRows.includes(row.id));
            const totalHours = sumTotalHours(pageData);

            setTotalSelectedHours(totalHours);
        } else {
            const pageData = filteredData?.filter(row => selectedRows.includes(row.id));
            const totalHours = sumTotalHours(pageData);

            setTotalSelectedHours(totalHours);
        }

    }, [selectedRows, filteredData]);

    useEffect(() => {
        if (selectAll === true) {
            const pageData = data;
            const totalHours = sumTotalHours(pageData);
            setTotalPageHours(totalHours);
        } else {
            const pageData = filteredData;
            const totalHours = sumTotalHours(pageData);
            setTotalPageHours(totalHours);
        }

    }, [currentPage, ROWS_PER_PAGE, filteredData]);

    console.log(totalSelectedHours, "totalSelectedHours")



    return (
        <Box sx={{ px: 10, py: 2 }}>
            <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold' }}>Time Sheet Report</Typography>
            <Grid container spacing={2} sx={{ pt: 4, py: 2 }}>
                <Grid item md={3} xs={6}>
                    <InputLabel>Designation </InputLabel>
                    <Select
                        fullWidth
                        value={jobType}
                        onChange={(e) => setJobType(e.target.value)}
                        sx={{ height: '32px', padding: '5px 10px', }}
                    >
                        <MenuItem value={""}>
                            All
                        </MenuItem>
                        <MenuItem value={"Provider"}>
                            Provider
                        </MenuItem>
                        <MenuItem value={"Biller"}>
                            Biller
                        </MenuItem>
                        <MenuItem value={"Employee_Office_Work"}>
                            Employee
                        </MenuItem>

                        <MenuItem value={"Consultant"}>
                            Consultant
                        </MenuItem>
                        {/* {['Provider', "Biller","Employee",].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))} */}
                    </Select>

                </Grid>
                <Grid item md={3} xs={6}>

                    <InputLabel>Name</InputLabel>
                    <Autocomplete
                        fullWidth
                        value={providerList}
                        onChange={(event, newValue) => {
                            setProviderList(newValue);
                        }}
                        options={providers}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{ '& input': { height: '0px', padding: '5px 10px' } }}
                            />
                        )}
                    />


                </Grid>

                <Grid item md={3} xs={6}>
                    <InputLabel id="facility-type-label">Date From </InputLabel>
                    {/* <TextField
                        type='date'
                        sx={{ width: '100%' }}
                        selected={startDate}
                        onChange={(date) => setDateRange([date, endDate])}
                        // isClearable={true}
                        // className='reactDateRangePicker1'
                        // selectsStart
                        // startDate={startDate}
                        // endDate={endDate}
                    /> */}
                    <DatePicker
                        // placeholderText='Select Start Date'
                        selected={startDate}
                        onChange={(date) => setDateRange([date, endDate])}
                        isClearable={true}
                        className='reactDateRangePicker1'
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    // popperPlacement='top'
                    />

                </Grid>



                <Grid item md={3} xs={6}>
                    <InputLabel id="facility-type-label">Date To </InputLabel>
                    <DatePicker
                        // placeholderText='Select End Date'
                        selected={endDate}
                        onChange={(date) => setDateRange([startDate, date])}
                        isClearable={true}
                        className='reactDateRangePicker1'
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        sx={{ width: "20rem !important" }}
                    />
                </Grid>

                <Grid item md={3} xs={6}>
                    <InputLabel>Facility Name</InputLabel>
                    <Autocomplete
                        fullWidth
                        value={facilityList}
                        onChange={(event, newValue) => {
                            setFacilityList(newValue);
                        }}

                        options={facilities}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => <TextField {...params} sx={{ '& input': { height: '0px', padding: '5px 10px', } }} />}
                    />

                </Grid>



                <Grid item md={3} xs={6}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        fullWidth
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        sx={{ height: '32px', padding: '5px 10px', }}
                    >
                        <MenuItem value={""}>
                            All
                        </MenuItem>
                        {['Pending', 'Approved', "Rejected"].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>

                </Grid>


                <Grid item md={6} xs={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'right', pt: 3 }}>
                        <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleSearch}
                            sx={{ background: '#0093AF !important', boxShadow: 'none !important', mr: 1 }}
                        >
                            Search
                        </Button>
                        <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleClearSearch}
                            sx={{ background: '#0093AF !important', boxShadow: 'none !important', }}
                        >
                            Clear
                        </Button>
                    </Box>
                </Grid>


            </Grid>

            {
                loading ? (
                    <Box sx={{ minHeight: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress sx={{ color: '#0093AF !important' }} />
                    </Box>
                ) :
                    filteredData?.length === 0 ? (
                        <Typography variant="body2" sx={{ textAlign: 'center', color: '#555', mt: 15, }}>
                            No Data Available
                        </Typography>
                    ) : (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', verticalAlign: 'middle', alignItems: 'center', }}>
                                <Typography sx={{ fontSize: '14px', my: 2 }}> <span style={{ fontWeight: 'bold' }}>Total Working Hours:</span> {totalPageHours}</Typography>

                                <Box>

                                    <Button
                                        disabled={selectedRows.length === 0}
                                        variant="contained"
                                        onClick={() => {
                                            // setStatus("Pending");
                                            handleMultipleTimesheet("Rejected");
                                        }}
                                        sx={{ boxShadow: 'none !important', background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', mr: 2 }}
                                    >
                                        Rejected
                                    </Button>

                                    <Button
                                        disabled={selectedRows.length === 0}
                                        variant="contained"
                                        onClick={() => {
                                            // setStatus("Approved");
                                            handleMultipleTimesheet("Approved");
                                        }}
                                        sx={{ boxShadow: 'none !important', background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', mr: 2 }}
                                    >
                                        Approved
                                    </Button>

                                    <Button
                                        disabled={selectedRows.length === 0}
                                        variant="contained"
                                        onClick={downloadSelectedRows}
                                        sx={{ boxShadow: 'none !important', background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', }}
                                    >
                                        Download Excel
                                    </Button>
                                </Box>
                            </Box>
                            <TableContainer component={Paper} className='scrollBar'>
                                <Table>
                                    <TableHead sx={{ backgroundColor: '#0093AF', color: '#fff' }}>
                                        <TableRow>
                                            <TableCell sx={{ py: 0, color: '#fff', display: 'flex', justifyContent: 'left', alignItems: 'center', verticalAlign: 'middle', width: '10px' }}>
                                                <Checkbox
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                    style={{ color: '#fff' }}
                                                />
                                                {/* <Typography>Select All</Typography> */}
                                            </TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Date</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Name</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Designation</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Facility Name</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Type of Job</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Start Time</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>End Time</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Lunch Break</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Total Hours</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Patient Seen</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Remarks</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Comment</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Status</TableCell>

                                            <TableCell sx={{ color: '#fff', py: 0, textAlign: 'center' }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(filteredData ? filteredData : []).map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ py: 0.3, width: '10px' }}>
                                                    <Checkbox
                                                        size='small'
                                                        checked={selectedRows.includes(row.id)}
                                                        onChange={() => handleCheckboxChange(row.id)}
                                                        style={{ color: '#0093AF' }}
                                                    />
                                                </TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.date}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.user}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{getUserRoleDisplay(row?.user_role)}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.facility}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.type_of_job}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{convertTo12HourFormat(row?.start_time)}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{convertTo12HourFormat(row?.end_time)}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.lunch_break}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row.total_hours.replace(/-\d+ day[s]?, /, "")}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.patient_seen}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.remarks}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.comment}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.status}</TableCell>
                                                {/* <TableCell sx={{ py: 0.3,  width:'fit-content'}}>{row?.date}</TableCell> */}

                                                <TableCell sx={{ py: 1.5, textAlign: 'center', display: 'flex', whiteSpace: 'nowrap' }}>
                                                    <IconButton color="primary" onClick={() => handleEditModal(row)}>
                                                        <EditIcon sx={{ color: '#0093AF', fontSize: "16px" }} />
                                                    </IconButton>
                                                    {/* <IconButton color="secondary" onClick={handleDelete}>
                                                <DeleteIcon sx={{ color: '#0093AF' }} />
                                            </IconButton> */}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Box sx={{ display: 'flex', justifyContent: 'right', verticalAlign: 'middle', alignItems: 'center' }}>
                                <CustomPagination
                                    count={Math.ceil(counts / ROWS_PER_PAGE)}
                                    page={currentPage}
                                    onChange={handleChangePage}
                                />
                            </Box>


                        </>
                    )
            }

            <Modal open={showModal} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 5, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Add Provider Master</Typography>
                        <IconButton onClick={closeModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <InputLabel>Date</InputLabel>
                                <CustomTextField
                                    value={date}
                                    setValue={setDate}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Provider</InputLabel>
                                <CustomTextField
                                    value={provider}
                                    setValue={setProvider}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Facilty Name</InputLabel>
                                <CustomTextField
                                    value={facilityName}
                                    setValue={setFacilityName}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Type of job</InputLabel>
                                <CustomTextField
                                    value={editJobType}
                                    setValue={setEditJobType}
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6}>
                                <InputLabel>Start Time</InputLabel>
                                <CustomTextField
                                    value={startTime}
                                    setValue={setStartTime}
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6}>
                                <InputLabel>End Time</InputLabel>
                                <CustomTextField
                                    value={endTime}
                                    setValue={setEndTime}
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6}>
                                <InputLabel>Lunch Break</InputLabel>
                                <CustomTextField
                                    value={lunch}
                                    setValue={setLunch}
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6}>
                                <InputLabel>Total Hours</InputLabel>
                                <CustomTextField
                                    value={totalHours}
                                    setValue={setTotalHours}
                                    fullWidth
                                    disabled
                                />
                            </Grid>


                            <Grid item md={6}>
                                <InputLabel>Patient Seen</InputLabel>
                                <CustomTextField
                                    value={patientSeen}
                                    setValue={setPatientSeen}
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6}>
                                <InputLabel>Comment</InputLabel>
                                <CustomTextField
                                    value={comment}
                                    setValue={setComment}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item md={6}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    fullWidth
                                    value={editStatus}
                                    onChange={(e) => setEditStatus(e.target.value)}
                                    sx={{ height: '40px', padding: '5px 10px', }}
                                >
                                    {['Rejected', 'Approved',].map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </Grid>

                        </Grid>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={handleSaveNewState}
                        sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', mt: 3, ml: 'auto' }}
                    >
                        Save
                    </Button>
                </Box>
            </Modal>

            <CustomSnackbar
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </Box >
    );
};

export default TimeSheetBody;

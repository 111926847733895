import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Checkbox,
    Grid,
    Typography,
    Box,
    Button,
    Menu,
    MenuItem,
    Modal,
    InputLabel,
    CircularProgress,
    Pagination,
    Autocomplete,
    FormControlLabel,
    ListItemText,
    TextField,
    Select
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextField from '../../../Utils/CustomTextfield';
import { deleteBiller, deleteEmployee, getAllAdmins, getAllBillers, getAllFacility, getEmployee, registeration, updateBiller, updateEmployee, updateRegisteration } from '../../../api/action';
import { useDispatch } from 'react-redux';
import CustomSnackbar from '../../../Utils/CustomSnackbar';
import CustomPagination from '../../../Utils/CustomPagination';
import DeleteModal from '../../../Utils/DeleteModal';
import { phoneRegex } from '../../../Utils/Regex';
import * as XLSX from 'xlsx';

const ROWS_PER_PAGE = 10; // Number of rows per page

const EmployeeBody = ({ onEdit, onDelete }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [searchFacilityCode, setSearchFacilityCode] = useState('');
    const [searchFacilityName, setSearchFacilityName] = useState('');
    const [searchFacilityType, setSearchFacilityType] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [newStateName, setNewStateName] = useState('');
    const [lastName, setLastName] = useState('');
    const [editLastName, seEditLastName] = useState("");
    const [newStateCode, setNewStateCode] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editStateName, setEditStateName] = useState('');
    const [editStateCode, setEditStateCode] = useState('');
    const [editEmail, setEditEmail] = useState('');
    const [editPhone, setEditPhone] = useState('');
    const [editPassword, setEditPassword] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [loading, setLoading] = useState('');
    const [billerId, setBillerID] = useState('');
    const [updated, setUpdated] = useState(false);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [emailError, setEmailError] = useState('');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const userRole = localStorage.getItem("userRole")
    const [facilityID, setFacilityID] = useState([]);
    const [facilityList, setFacilityList] = useState([]);
    const [allFacilties, setAllFacilities] = useState([]);
    const [type, setType] = useState("");
    const [editType, setEditType] = useState("");
    const [reporter, setReporter] = useState([]);
    const [editReporter, setEditReporter] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [editStatus, setEditStatus] = useState(false);
    const [userId, setUserId] = useState('');

    console.log(reporter)

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllAdmins(payload,
                (data) => {
                    setLoading(false);
                    const filteredAdmins = data?.data?.Data.filter(admin => admin.user_role === "Admin Finance" || admin.user_role === "Admin Operations");
                    console.log(filteredAdmins)

                    setAdmins(filteredAdmins);
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    console.log(admins)

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getEmployee(payload,
                (data) => {
                    console.log(data?.data)
                    setLoading(false);
                    setData(data?.data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllFacility(payload,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setAllFacilities(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    console.log(data)

    // useEffect(() => {
    //     // Extract reporter names from the selected row data
    //     const reporterNames = selectedRowData.reporters.map(reporter => reporter.reporter_name);

    //     // Filter the admins to match with the reporter names
    //     const matchedAdmins = admins.filter(admin =>
    //         reporterNames.includes(admin.Full_Name)
    //     );

    //     // Set the matched admins to editReporter
    //     setEditReporter(matchedAdmins);
    // }, [selectedRow, admins]);

    const handleOpenEditModal = (selectedRow) => {
        console.log(selectedRow)

        // Extract reporter names from the selected row data
        const reporterNames = selectedRow.reporters.map(reporter => reporter.reporter_name);

        // Filter the admins to match with the reporter names
        const matchedAdmins = admins.filter(admin =>
            reporterNames.includes(admin.Full_Name)
        );

        // Set the matched admins to editReporter
        setEditReporter(matchedAdmins);
        setEditModalOpen(true);
        // Populate state variables with selected row data
        setEditStateName(selectedRow.First_Name);
        seEditLastName(selectedRow.Last_Name);
        setEditStateCode(selectedRow.emp_code);
        setEditEmail(selectedRow.email);
        setEditPhone(selectedRow.Phone_number);
        setEditPassword(selectedRow.password);
        setBillerID(selectedRow.user_id);
        // setEditReporter(selectedRow?.reporters);
        setEditStatus(selectedRow.is_active)
        // setUserId(selectedRow.user_id)
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const selectedCodes = data.map((row) => row.emp_code);
            setSelectedRows(selectedCodes);
        } else {
            setSelectedRows([]);
        }
        setSelectAll(event.target.checked);
    };

    const handleCheckboxChange = (facilityCode) => {
        const isSelected = selectedRows.includes(facilityCode);
        let updatedSelectedRows = [];
        if (isSelected) {
            updatedSelectedRows = selectedRows.filter((code) => code !== facilityCode);
        } else {
            updatedSelectedRows = [...selectedRows, facilityCode];
        }
        setSelectedRows(updatedSelectedRows);
        setSelectAll(updatedSelectedRows.length === data.length);
    };

    const handleEditClick = (event, selectedFacility) => {
        setAnchorEl(event.currentTarget);
        setSelectedFacility(selectedFacility);
    };

    const handleEditClose = () => {
        setAnchorEl(null);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };


    const handleDelete = () => {
        onDelete(selectedRows);
        setSelectedRows([]);
    };

    const handleAddNew = () => {
        setShowModal(true);
    }

    useEffect(() => {
        setCurrentPage(1)
    }, [searchFacilityCode, searchFacilityName, searchFacilityName])

    const filteredData = data?.filter((row) => {
        const matchesFacilityCode = row.emp_code.toLowerCase().includes(searchFacilityCode.toLowerCase());
        const matchesFacilityName = row.First_Name.toLowerCase().includes(searchFacilityName.toLowerCase()) ||
            row.Last_Name.toLowerCase().includes(searchFacilityName.toLowerCase());
        return matchesFacilityCode && matchesFacilityName;
    });

    const handleClearSearch = () => {
        setSearchFacilityCode('');
        setSearchFacilityName('');
        setSearchFacilityType('');
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNewStateName('');
        setNewStateCode('');
    };

    const validateEmail = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(value);
        return isValid;
    };

    const handleAddNewBiller = (e) => {

        if (!email || !password || !newStateName || !newStateCode) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (!phoneRegex.test(phone)) {
            setLoading(false);
            setSnackbarMessage('Please enter a valid phone number.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address.');
            setLoading(false);
            setSnackbarMessage('Please enter a valid email address.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            email,
            password,
            password2: password,
            first_name: newStateName,
            last_name: lastName,
            emp_code: newStateCode,
            phone_number: phone,
            user_role: type,
            facility_master_ids: facilityID,
            reporter_id: reporter.map(item => item.user_id),
            is_active: true

        };

        setLoading(true);
        dispatch(
            registeration(
                payload,
                (data) => {
                    console.log(data)
                    if (data.status === 200) {
                        setLoading(false);
                        setSnackbarMessage("Employee added successfully");
                        setSnackbarOpen(true);
                        setSnackbarSeverity("success");
                        setShowModal(false);
                        setUpdated(prevState => !prevState);
                        setEmail("")
                        setPassword("")
                        setNewStateCode("")
                        setNewStateName("")
                        setPhone("")
                        setLastName("")
                        setFacilityList([])
                    }
                    else if (data.status === 226) {
                        setLoading(false);
                        setSnackbarMessage("Email already exist");
                        setSnackbarOpen(true);
                        setSnackbarSeverity("error");
                    }
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };
    console.log(billerId)

    const handleUpdateBiller = (e) => {
        e.preventDefault();

        if (!editPassword || !editStateName || !editStateCode) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }



        const payload = {
            password: editPassword,
            First_Name: editStateName,
            Last_Name: editLastName,
            user_id: billerId,
            Phone_number: editPhone,
            // user_role: type,
            // facility_master_ids: facilityID,
            reporter_id: editReporter.map(item => item.user_id),
            is_active: editStatus,
            // user_id: userId,
        };

        setLoading(true);
        dispatch(
            updateEmployee(
                payload, billerId,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Employee updated successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setEditModalOpen(false);
                    setUpdated(prevState => !prevState);

                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleDeleteBiller = (user_id) => {
        const payload = {
            user_id
        };

        setLoading(true);
        dispatch(
            deleteEmployee(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Employee deleted successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setEditModalOpen(false);
                    setUpdated(prevState => !prevState);
                    setOpenDeleteModal(false)

                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };


    const handleSaveEditedState = () => {
        // Add your logic to save the new state (e.g., API call, updating state)
        // After saving, close the modal
        setShowModal(false);
    };

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
    };

    const handleDeleteModal = (id) => {
        setOpenDeleteModal(true);
        setBillerID(id)
    };

    // Pagination Handlers
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    // Slice the data array to get only the data for the current page
    const paginatedData = filteredData.slice(startIndex, startIndex + ROWS_PER_PAGE);
    // Calculate the end index of the data for the current page
    const endIndex = startIndex + ROWS_PER_PAGE;

    const downloadSelectedRows = () => {
        // Filter rows based on selectedRows array
        const exportData = filteredData
            .filter(row => selectedRows.includes(row.emp_code))
            .map(row => ({
                'Employee Code': row.emp_code,
                'Employee Name': row?.First_Name + " " + row.Last_Name,
                'Email ID': row.email,
                'Phone Number': row.Phone_number,
                'Reporting Manager': row.reporter_name,
                'Status': row.is_active === true ? "Active" : "Inactive",
            }));

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(exportData);
        const columnWidths = [
            { wch: 15 }, // Width of Date column
            { wch: 20 }, // Width of Provider column
            { wch: 40 }, // Width of Facility Name column
            { wch: 20 }, // Width of Type of Job column
            { wch: 50 }, // Width of Type of Job column
            { wch: 20 }, // Width of Type of Job column
        ];

        ws['!cols'] = columnWidths;

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

        // Save the workbook as an Excel file
        XLSX.writeFile(wb, 'Employees.xlsx');
    };



    return (
        <Box sx={{ px: 10, py: 2 }}>
            <DeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                loading={loading}
                setLoading={setLoading}
                handleDeletefunction={handleDeleteBiller}
                id={billerId}
            />
            <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', mb: 2 }}>Search Employee</Typography>
            <Grid container spacing={5} >
                <Grid item md={3} xs={6}>
                    <InputLabel>Employee Code</InputLabel>
                    <CustomTextField
                        value={searchFacilityCode}
                        setValue={setSearchFacilityCode}
                    />
                </Grid>
                <Grid item md={3} xs={6}>
                    <InputLabel>Employee Name</InputLabel>
                    <CustomTextField
                        value={searchFacilityName}
                        setValue={setSearchFacilityName}
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                        <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleClearSearch}
                            sx={{ background: '#0093AF !important', boxShadow: 'none !important', my: 3, mx: 1 }}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={userRole !== "Admin"}
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleAddNew}
                            sx={{ background: userRole !== "Admin" ? "#d3d3d3" : '#0093AF !important', boxShadow: 'none !important', my: 3, mx: 1 }}
                        >
                            Add New
                        </Button>
                        {/* <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleDelete}
                            disabled={selectedRows.length === 0}
                            sx={{ background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', boxShadow: 'none !important', my: 3, mx: 1 }}
                        >
                            Delete
                        </Button> */}

                        <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={downloadSelectedRows}
                            disabled={selectedRows.length === 0}
                            sx={{ boxShadow: 'none !important', background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', my: 3, mx: 1 }}
                        >
                            Download Excel
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{}}>
                {loading ? ( // Render CircularProgress while loading is true
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
                        <CircularProgress sx={{ color: '#0093AF !important' }} />
                    </Box>
                ) : (
                    filteredData.length === 0 ? (
                        <Typography variant="body2" sx={{ textAlign: 'center', color: '#555', mt: 15 }}>
                            No Data Available
                        </Typography>
                    ) : (
                        <>

                            <TableContainer component={Paper} className='scrollBar'>
                                <Table>
                                    <TableHead sx={{ backgroundColor: '#0093AF', color: '#fff' }}>
                                        <TableRow>
                                            <TableCell sx={{ py: 0, color: '#fff', display: 'flex', justifyContent: 'left', alignItems: 'center', verticalAlign: 'middle' }}>
                                                <Checkbox
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                    sx={{ color: '#fff !important' }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Employee Code</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Employee Name</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Email ID</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Phone Number</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Reporting Manager</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Status</TableCell>
                                            {/* <TableCell sx={{ color: '#fff', py: 0 }}>Password</TableCell> */}
                                            <TableCell sx={{ color: '#fff', py: 0 }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredData.slice(startIndex, endIndex).map((row) => (
                                            <TableRow key={row.emp_code}>
                                                <TableCell sx={{ py: 0.3 }}>
                                                    <Checkbox
                                                        size="small"
                                                        checked={selectedRows.includes(row.emp_code)}
                                                        onChange={() => handleCheckboxChange(row.emp_code)}
                                                        style={{ color: '#0093AF' }}
                                                    />
                                                </TableCell>
                                                <TableCell className="fs12" sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.emp_code}</TableCell>
                                                <TableCell className="fs12" sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.First_Name + " " + row.Last_Name}</TableCell>
                                                <TableCell className="fs12" sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.email}</TableCell>
                                                <TableCell sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.Phone_number}</TableCell>
                                                <TableCell sx={{ py: 0.3, whiteSpace: 'nowrap' }}>
                                                    {row?.reporters.map((item, index) => (
                                                        <Box className="fs12" key={index}>{item?.reporter_name}</Box>
                                                    ))}
                                                </TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap', whiteSpace: 'nowrap' }}>{row?.is_active === true ? "Active" : "Inactive"}</TableCell>
                                                {/* <TableCell className="fs12" sx={{ py: 0.3, whiteSpace: 'nowrap'  }}>
                                                    {row.password}
                                                </TableCell> */}
                                                <TableCell sx={{ py: 0.3, whiteSpace: 'nowrap' }}>
                                                    <IconButton disabled={userRole !== "Admin"} color="primary" onClick={() => handleOpenEditModal(row)}>
                                                        <EditIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                    </IconButton>
                                                    <IconButton disabled={userRole !== "Admin"} color="secondary" onClick={() => handleDeleteModal(row.user_id)}>
                                                        <DeleteIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <CustomPagination
                                count={Math.ceil(filteredData.length / ROWS_PER_PAGE)}
                                page={currentPage}
                                onChange={handleChangePage}
                            />
                        </>
                    )
                )}


                <CustomSnackbar
                    open={snackbarOpen}
                    handleClose={handleSnackbarClose}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            </Box>

            {/* ADD MODAL */}

            <Modal open={showModal} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <Box className='scrollBar-Y' sx={{ background: '#fff', width: '40vw', px: 5, py: 5, mx: 'auto', borderRadius: '6px', maxHeight: '80vh', overflowY: 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Add New Employee</Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <InputLabel>Employee code</InputLabel>
                                <CustomTextField
                                    label="Biller code"
                                    value={newStateCode}
                                    setValue={setNewStateCode}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Employee First Name</InputLabel>
                                <CustomTextField
                                    label="First Name"
                                    value={newStateName}
                                    setValue={setNewStateName}
                                />
                            </Grid>

                            <Grid item md={6}>
                                <InputLabel>Employee Last Name</InputLabel>
                                <CustomTextField
                                    label="Last Name"
                                    value={lastName}
                                    setValue={setLastName}
                                />
                            </Grid>

                            <Grid item md={6}>
                                <InputLabel>Email</InputLabel>
                                <CustomTextField
                                    label="Email"
                                    value={email}
                                    setValue={setEmail}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Phone Number</InputLabel>
                                <CustomTextField
                                    label="Phone Number"
                                    value={phone}
                                    setValue={setPhone}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Password</InputLabel>
                                <CustomTextField
                                    label="Password"
                                    value={password}
                                    setValue={setPassword}
                                />
                            </Grid>

                            <Grid item md={6} xs={6}>
                                <InputLabel>Employee Type</InputLabel>
                                <Select
                                    fullWidth
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    sx={{ height: '32px', padding: '5px 10px', }}
                                >


                                    <MenuItem value={"Employee_Office_Work"}>
                                        Office
                                    </MenuItem>

                                    <MenuItem value={"Employee_Admin_Work"}>
                                        Administration
                                    </MenuItem>

                                    <MenuItem value={"Consultant"}>
                                        Consultant
                                    </MenuItem>
                                </Select>
                            </Grid>

                            {/* <Grid item md={6} xs={6}>
                                <InputLabel>Reporting Manager</InputLabel>
                                <Select
                                    fullWidth
                                    value={reporter}
                                    onChange={(e) => setReporter(e.target.value)}
                                    sx={{ height: '32px', padding: '5px 10px', }}
                                >

                                    {admins?.map((item, index) => (
                                        <MenuItem key={index} value={item?.user_id}>
                                            {item?.Full_Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid> */}

                            <Grid item md={6} xs={6}>
                                <InputLabel id="facility-name-label">Reporting Manager</InputLabel>
                                <Autocomplete
                                    className='scrollBar'
                                    fullWidth
                                    multiple
                                    value={reporter}
                                    onChange={(event, newValue) => {
                                        setReporter(newValue);
                                    }}
                                    // sx={{ width: '15vw !important' }}
                                    clearIcon={null}
                                    options={admins} // Pass the array directly
                                    getOptionLabel={(option) => option.Full_Name}
                                    getOptionSelected={(option, value) => option.Full_Name === value.Full_Name}
                                    isOptionEqualToValue={(option, value) => option.Full_Name === value.Full_Name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ '& input': { height: '0px', fontSize: '14px' } }}
                                        />
                                    )}
                                />
                            </Grid>


                            <Grid item md={12}>
                                <InputLabel>Facilty</InputLabel>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    fullWidth
                                    value={facilityList}
                                    onChange={(event, newValue) => {
                                        setFacilityList(newValue);
                                        // Log the IDs of selected options
                                        setFacilityID(newValue.map(option => option.id));
                                    }}
                                    options={allFacilties}
                                    getOptionLabel={(option) => `${option.facility_name}`}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <FormControlLabel
                                                control={<Checkbox checked={selected} />}
                                                label={<ListItemText primary={option.facility_name} />}
                                            />
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ '& input': { height: '0px', padding: '5px 10px' } }}
                                        />
                                    )}
                                />
                            </Grid>

                        </Grid>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={handleAddNewBiller}
                        sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', mt: 3, ml: 'auto' }}
                    >
                        {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Add"}
                    </Button>
                </Box>
            </Modal>

            {/* EDIT MODAL */}

            <Modal open={editModalOpen} onClose={handleCloseEditModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 5, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Edit Employee Detail</Typography>
                        <IconButton onClick={handleCloseEditModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <InputLabel>Employee code</InputLabel>
                                <CustomTextField
                                    label="Biller code"
                                    value={editStateCode}
                                    setValue={setEditStateCode}
                                    disabled
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>First Name</InputLabel>
                                <CustomTextField
                                    label="Biller Name"
                                    value={editStateName}
                                    setValue={setEditStateName}
                                />
                            </Grid>

                            <Grid item md={6}>
                                <InputLabel>Last Name Name</InputLabel>
                                <CustomTextField
                                    label="Biller Name"
                                    value={editLastName}
                                    setValue={seEditLastName}
                                />
                            </Grid>

                            <Grid item md={6}>
                                <InputLabel>Email</InputLabel>
                                <CustomTextField
                                    label="Email"
                                    value={editEmail}
                                    setValue={setEditEmail}
                                    disabled
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Phone Number</InputLabel>
                                <CustomTextField
                                    label="Phone Number"
                                    value={editPhone}
                                    setValue={setEditPhone}
                                />
                            </Grid>

                            {/* <Grid item md={6} xs={6}>
                                <InputLabel>Employee Type</InputLabel>
                                <Select
                                    fullWidth
                                    value={editType}
                                    onChange={(e) => setEditType(e.target.value)}
                                    sx={{ height: '32px', padding: '5px 10px', }}
                                >


                                    <MenuItem value={"Employee_Office_Work"}>
                                        Office
                                    </MenuItem>

                                    <MenuItem value={"Employee_Admin_Work"}>
                                        Administration
                                    </MenuItem>
                                </Select>
                            </Grid> */}

                            <Grid item md={6} xs={6}>
                                <InputLabel>Reporting Manager</InputLabel>
                                {/* <Select
                                    fullWidth
                                    value={editReporter}
                                    onChange={(e) => setEditReporter(e.target.value)}
                                    sx={{ height: '32px', padding: '5px 10px', }}
                                >

                                    {admins?.map((item, index) => (
                                        <MenuItem key={index} value={item?.user_id}>
                                            {item?.Full_Name}
                                        </MenuItem>
                                    ))}
                                </Select> */}

                                <Autocomplete
                                    className='scrollBar'
                                    fullWidth
                                    multiple
                                    value={editReporter}
                                    onChange={(event, newValue) => {
                                        setEditReporter(newValue);
                                    }}
                                    // sx={{ width: '15vw !important' }}
                                    clearIcon={null}
                                    options={admins} // Pass the array directly
                                    getOptionLabel={(option) => option.Full_Name}
                                    getOptionSelected={(option, value) => option.Full_Name === value.Full_Name}
                                    isOptionEqualToValue={(option, value) => option.Full_Name === value.Full_Name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ '& input': { height: '0px', fontSize: '14px' } }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item md={6} xs={6}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    fullWidth
                                    value={editStatus}
                                    onChange={(e) => setEditStatus(e.target.value)}
                                    sx={{ height: '32px', padding: '5px 5px', fontSize: '12px' }}
                                >


                                    <MenuItem value={true}>
                                        Active
                                    </MenuItem>

                                    <MenuItem value={false}>
                                        Inactive
                                    </MenuItem>
                                </Select>
                            </Grid>


                            {/* <Grid item md={6}>
                                <InputLabel>Password</InputLabel>
                                <CustomTextField
                                    label="Password"
                                    value={editPassword}
                                    setValue={setEditPassword}
                                />
                            </Grid> */}
                        </Grid>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={handleUpdateBiller}
                        sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', mt: 3, ml: 'auto' }}
                    >
                        {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Update"}
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default EmployeeBody;

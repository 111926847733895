import React, { useState } from 'react';
import { Box, TextField, Button, Paper, Typography, CircularProgress, InputLabel } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../Image/Logo.png';
import CustomSnackbar from '../Utils/CustomSnackbar';
import { useDispatch } from 'react-redux';
import { emailLink, login, resetPaswword } from '../api/action';
import { useLocation } from 'react-router-dom';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [loading, setLoading] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const validateEmail = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(value);

        setEmailError(isValid ? '' : 'Invalid email address');

        return isValid;
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        validateEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    function getLastTwoParts(url) {
        // Remove the trailing '/' if present
        if (url.endsWith('/')) {
            url = url.slice(0, -1);
        }

        // Split the URL by '/'
        var parts = url.split('/');

        // Extract the last two parts
        var secondLastPart = parts[parts.length - 2];
        var lastPart = parts[parts.length - 1];

        // Concatenate the last two parts with '/'
        var lastTwoParts = secondLastPart + '/' + lastPart;

        // Return the last two parts
        return lastTwoParts;
    }

    // Example usage:
    var url = location?.pathname
    var result = getLastTwoParts(url);





    const handleLoginClick = async (e) => {
        e.preventDefault();

        // if (!validateEmail(email)) {
        //     return;
        // }

        if (!email || !password) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (email !== password) {
            setLoading(false);
            setSnackbarMessage('Password and Approved Password should be same.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        localStorage.clear();
        sessionStorage.clear();

        const payload = {
            password2: email,
            password: password,
            // isDietition: true
        };

        setLoading(true);
        dispatch(
            resetPaswword(
                payload, result,
                (data) => {
                    setLoading(false);
                    console.log(data)
                    if (data?.data?.msg === "Password Reset Successfully") {
                        setSnackbarMessage(data?.data?.msg);
                        setSnackbarSeverity("success");
                        setSnackbarOpen(true);
                        setTimeout(() => {
                            // setSnackbarOpen(false);
                            navigate('/')
                        }, 2000);
                        setEmail("")
                        setPassword("")
                    }
                    else {
                        setSnackbarMessage(data?.data?.msg);
                        setSnackbarSeverity("error");
                        setSnackbarOpen(true);
                    }
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);

                }
            )
        );
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const userRole = 'Provider';
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                background: '#0093AF',
                flexDirection: 'column',
                minHieght: '100vh'
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pb: 3, color: '#0093AF', fontWeight: 'bold', verticalAlign: 'middle', }}>
                <img src={Logo} height={"70px"} width={"200px"} style={{ borderRadius: '10px' }} />
            </Box>

            <Paper elevation={3} sx={{ padding: 3, minWidth: 400, py: 5, px: 5 }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold', color: '#0093AF', textAlign: 'center' }}>Reset Your Password</Typography>
                {/* <InputLabel htmlFor="email">Please enter registered email</InputLabel> */}
                <InputLabel >Enter Password</InputLabel>
                <TextField
                    sx={{ mt: 0 }}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                />

                <InputLabel >Confirm Password</InputLabel>
                <TextField
                    // label="Approved Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="password"
                    value={email}
                    onChange={handleEmailChange}
                    sx={{ mt: 0 }}
                />


                <Button variant="contained" fullWidth sx={{ background: '#0093AF !important', py: 1, mt: 2, textDecoration: 'none !important' }} onClick={handleLoginClick}>
                    <Typography elevation={0} sx={{ color: '#fff', textDecoration: 'none !important' }}>
                        {loading ? (
                            <CircularProgress size={18} sx={{ color: '#fff' }} />
                        ) : (
                            "Reset Password"
                        )}
                    </Typography>
                </Button>

                <Typography
                    sx={{ color: '#0093AF', textAlign: 'right', cursor: 'pointer', fontSize: '12px', mt: 3 }}
                    onClick={() => navigate('/')}
                >
                    Back to Login
                </Typography>
            </Paper>

            <CustomSnackbar
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </Box>
    );
};

export default ResetPassword;

export const endpoints = {
  REGISTERATION: '/register/',
  LOGIN: '/login/',
  RESENDEMAILLINK: '/send-reset-password-email/',
  RESETPASSWORD: '/reset-password/',
  BILLER: '/Biller/',
  PROVIDER: '/Provider/',
  ADMIN: '/Adminprofile/',
  STATEMASTER: '/statemaster/',
  FACILITYTYPE: '/facilitytype/',
  VISITREASON: '/visitreason/',
  FACILITY:'/facility/',
  getProviderFaciltyById: "/FacilityListProvider/",
  getProviderFaciltyTypeById: "/FacilityTypeListProvider/",
  providerTimeSheet: "/timesheet/",
  adminTimeSheet: "/AdminTimeSheetTest/",
  // adminTimeSheet: "/AdminTimeSheetView/",
  providerPatients: "/superbiller/filterview/",
  superBill: "/superbillerview/",
  superBillSubmit: "/SuperBillAuthorizationView/",
  billerProviderMapping: "/ProviderBillersView/",
  // superBillAdmin: "/superbiller/adminview/",
  superBillAdmin: "/superbiller/adminviewtest/",
  // superBillProvider: "/provider/reports/",
  superBillProvider: "/provider/reportstest/",

  superBillBiller: "/billerproviders/superbillersview/",
  providersbyBillerId: "/BillerProviderListView/",
  excelUpload:'superbiller/uploadapi/',
  updateMultipleReportBybiller: "/ReportsMultiUpdateView/",

  deleteSuperbill:'provider/reportstest/',

  adminGraph:'/DashboardViewTesting/',
  // adminGraph:'/DashboardView/',

  providerGraph:'/ProviderDashboardViewCheck/',
  // providerGraph:'/ProviderDashboardView/',

  patientSeen:'/lastmonth/patientseenview/',
  employee:'/employeeprofileview/',
  employeeProviderList:'/employeeproviderlist/',

  providerListByFacility:'/filter/provider-facilitydata/',

  updateMultipleTimesheet: '/update/timesheetstatus/',
  providerCountGraph: '/superbiller/monthly-count/',
  copyData: '/provider/copydata/'
};
import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Checkbox,
    TextField,
    Grid,
    Typography,
    Box,
    Button,
    Menu,
    MenuItem,
    Modal,
    InputLabel,
    Pagination,
    CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextField from '../Utils/CustomTextfield';
import CustomPagination from '../Utils/CustomPagination';
import { useDispatch } from 'react-redux';
import { addStateMaster, deleteStateMaster, getAllStateMasters, updateStateMaster } from '../api/action';
import CustomSnackbar from '../Utils/CustomSnackbar';
import DeleteModal from '../Utils/DeleteModal';
import * as XLSX from 'xlsx';

const StateMasterBody = ({ onEdit, onDelete }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [searchStateCode, setSearchStateCode] = useState('');
    const [searchStateName, setSearchStateName] = useState('');
    const [searchStateType, setSearchStateType] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [newStateName, setNewStateName] = useState('');
    const [newStateCode, setNewStateCode] = useState('');
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editStateName, setEditStateName] = useState('');
    const [editStateCode, setEditStateCode] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState('');
    const [updated, setUpdated] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [stateMasterId, setStateMasterId] = useState('');
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const userRole = localStorage.getItem("userRole")

    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllStateMasters(payload,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setData(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const selectedCodes = data.map((row) => row.state_code);
            setSelectedRows(selectedCodes);
        } else {
            setSelectedRows([]);
        }
        setSelectAll(event.target.checked);
    };

    const handleCheckboxChange = (stateCode) => {
        const isSelected = selectedRows.includes(stateCode);
        let updatedSelectedRows = [];
        if (isSelected) {
            updatedSelectedRows = selectedRows.filter((code) => code !== stateCode);
        } else {
            updatedSelectedRows = [...selectedRows, stateCode];
        }
        setSelectedRows(updatedSelectedRows);
        setSelectAll(updatedSelectedRows.length === data.length);
    };

    const handleEditClick = (event, selectedState) => {
        setAnchorEl(event.currentTarget);
        setSelectedState(selectedState);

        // Prefill values for edit modal
        setEditStateName(selectedState.state_Name);
        setEditStateCode(selectedState.state_code);
        setStateMasterId(selectedState.id);

        // Open edit modal
        setEditModalOpen(true);
    };

    const handleEditClose = () => {
        setEditModalOpen(false);
    };

    const handleDelete = () => {
        onDelete(selectedRows);
        setSelectedRows([]);
    };

    const handleAddNew = () => {
        setShowModal(true);
    }

    useEffect(() => {
        setPage(1)
     }, [searchStateCode, searchStateName])

    const filteredData = data.filter((row) => {
        const matchesStateCode = row.state_code.toLowerCase().includes(searchStateCode.toLowerCase());
        const matchesStateName = row.state_Name.toLowerCase().includes(searchStateName.toLowerCase());

        return matchesStateCode && matchesStateName;
    });

    const handleClearSearch = () => {
        setSearchStateCode('');
        setSearchStateName('');
        setSearchStateType('');
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNewStateName('');
        setNewStateCode('');
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handleAddNewStateMaster = (e) => {
        e.preventDefault();

        if (!newStateName || !newStateCode) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            state_Name: newStateName,
            state_code: newStateCode,
        };

        setLoading(true);
        dispatch(
            addStateMaster(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("State Master added successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setShowModal(false);
                    setUpdated(prevState => !prevState);
                    setNewStateCode("")
                    setNewStateName("")
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleUpdateBiller = (e) => {
        e.preventDefault();

        if (!editStateName || !editStateCode) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            state_Name: editStateName,
            state_code: editStateCode,
            state_id: stateMasterId,
        };

        setLoading(true);
        dispatch(
            updateStateMaster(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("State Master updated successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setEditModalOpen(false);
                    setUpdated(prevState => !prevState);

                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleDeleteBiller = (id) => {
        console.log(id)
        const payload = {
            state_id: id
        };

        setLoading(true);
        dispatch(
            deleteStateMaster(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("State Master deleted successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setEditModalOpen(false);
                    setUpdated(prevState => !prevState);
                    setOpenDeleteModal(false)

                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleDeleteModal = (id) => {
        setOpenDeleteModal(true);
        setStateMasterId(id)
    };

    const downloadSelectedRows = () => {
        // Filter rows based on selectedRows array
        const exportData = filteredData
            .filter(row => selectedRows.includes(row.state_code))
            .map(row => ({
                'State Code': row.state_code,
                'State Name': row.state_Name,
            }));

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(exportData);
        const columnWidths = [
            { wch: 15 }, // Width of Date column
            { wch: 40 }, // Width of Provider column

        ];

        ws['!cols'] = columnWidths;

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

        // Save the workbook as an Excel file
        XLSX.writeFile(wb, 'State Master.xlsx');
    };


    return (
        <Box sx={{ px: 10, py: 1 }}>

            <DeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                loading={loading}
                setLoading={setLoading}
                handleDeletefunction={handleDeleteBiller}
                id={stateMasterId}
            />
            <CustomSnackbar
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />

            <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', mb: 2 }}>State Master</Typography>
            <Grid container spacing={5} sx={{}}>
                <Grid item md={3} xs={6}>
                    <InputLabel >State Code</InputLabel>
                    <CustomTextField
                        value={searchStateCode}
                        setValue={setSearchStateCode}
                    />
                </Grid>
                <Grid item md={3} xs={6}>
                    <InputLabel>State Name</InputLabel>
                    <CustomTextField
                        value={searchStateName}
                        setValue={setSearchStateName}
                    />
                </Grid>
                <Grid item md={6} xs={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle', py: 3 }}>
                        <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleClearSearch}
                            sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', ml: 'auto', }}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={userRole !== "Admin"}
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleAddNew}
                            sx={{ boxShadow: 'none !important', display: 'block',ml:1, background: userRole !== "Admin" ? "#899499" : '#0093AF' }}
                        >
                            Add New
                        </Button>

                        <Button
                            className='buttonPadding'
                            disabled={selectedRows.length === 0}
                            variant="contained"
                            onClick={downloadSelectedRows}
                            sx={{ boxShadow: 'none !important', background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important',ml:1 }}
                        >
                            Download Excel
                        </Button>

                        {/* <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleDelete}
                            disabled={selectedRows.length === 0}
                            sx={{ background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', boxShadow: 'none !important', display: 'block', ml: 'auto', mr: 2 }}
                        >
                            Delete
                        </Button> */}


                    </Box>
                </Grid>
            </Grid>


            {
                loading ? (
                    <Box sx={{ minHeight: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress sx={{ color: '#0093AF !important' }} />
                    </Box>
                ) : (
                    filteredData.length === 0 ? (
                        <Typography variant="body2" sx={{ textAlign: 'center', color: '#555', mt: 15 }}>
                            No Data Available
                        </Typography>
                    ) : (
                        <React.Fragment>
                            <TableContainer component={Paper} className='scrollBar'>
                                <Table>
                                    <TableHead sx={{ backgroundColor: '#0093AF', color: '#fff' }}>
                                        <TableRow>
                                            <TableCell sx={{ py: 0, color: '#fff', width: "5rem" }}>
                                                <Checkbox
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                    style={{ color: '#fff', py: 0 }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0 }}>State Code</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0 }}>State Name</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0, textAlign: 'center' }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row) => (
                                            <TableRow key={row.stateCode}>
                                                <TableCell sx={{ py: "0px !important" }}>
                                                    <Checkbox
                                                        size="small"
                                                        checked={selectedRows.includes(row.state_code)}
                                                        onChange={() => handleCheckboxChange(row.state_code)}
                                                        style={{
                                                            color: '#0093AF',
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell className='fs12' sx={{ py: 0 }}>{row.state_code}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0 }}>{row.state_Name}</TableCell>
                                                <TableCell sx={{ py: 0, textAlign: 'center' }}>
                                                    <IconButton disabled={userRole !== "Admin"} color="primary" onClick={(e) => handleEditClick(e, row)}>
                                                        <EditIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                    </IconButton>
                                                    <IconButton disabled={userRole !== "Admin"} color="secondary" onClick={() => handleDeleteModal(row.id)}>
                                                        <DeleteIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <CustomPagination
                                count={Math.ceil(filteredData.length / rowsPerPage)}
                                page={page}
                                onChange={handleChangePage}
                            />
                        </React.Fragment>
                    )
                )
            }




            <Modal open={showModal} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 10, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Add New State</Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <InputLabel>State Name</InputLabel>
                        <CustomTextField
                            value={newStateName}
                            setValue={setNewStateName}
                        />

                        <InputLabel sx={{ mt: 2 }}>State Code</InputLabel>
                        <CustomTextField
                            value={newStateCode}
                            setValue={setNewStateCode}
                        />
                    </Box>

                    <Button
                        variant="contained"
                        onClick={handleAddNewStateMaster}
                        sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', mt: 3, ml: 'auto', }}
                    >
                        {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Save"}
                    </Button>
                </Box>
            </Modal>

            {/* Edit Modal */}
            <Modal open={editModalOpen} onClose={handleEditClose} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 10, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Edit State</Typography>
                        <IconButton onClick={handleEditClose}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <InputLabel>State Name</InputLabel>
                        <CustomTextField
                            value={editStateName}
                            setValue={setEditStateName}
                        />

                        <InputLabel sx={{ mt: 2 }}>State Code</InputLabel>
                        <CustomTextField
                            value={editStateCode}
                            setValue={setEditStateCode}
                        />
                    </Box>

                    <Button
                        variant="contained"
                        className='buttonPadding'
                        onClick={handleUpdateBiller}
                        sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', mt: 3, ml: 'auto' }}
                    >
                        {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Update"}
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default StateMasterBody;
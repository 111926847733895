import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Checkbox,
    Grid,
    Typography,
    Box,
    Button,
    Modal,
    InputLabel,
    CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import CustomTextField from '../Utils/CustomTextfield';
import CustomPagination from '../Utils/CustomPagination';
import { addFacilityType, deleteFacilityType, getAllFacilityTypes, updateFacilityType } from '../api/action';
import { useDispatch } from 'react-redux';
import CustomSnackbar from '../Utils/CustomSnackbar';
import DeleteModal from '../Utils/DeleteModal';
import * as XLSX from 'xlsx';
import CloseIcon from '@mui/icons-material/Close';

const FacilityTypeMasterBody = ({ onEdit, onDelete }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchFacilityCode, setSearchFacilityCode] = useState('');
    const [searchFacilityName, setSearchFacilityName] = useState('');
    const [searchFacilityType, setSearchFacilityType] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [newFacilityCode, setNewFacilityCode] = useState('');
    const [newFacilityName, setNewFacilityName] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [editFacilityCode, setEditFacilityCode] = useState('');
    const [editFacilityName, setEditFacilityName] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState('');
    const [updated, setUpdated] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [id, setId] = useState('');
    const [editModalOpen, setEditModalOpen] = useState(false);
    const dispatch = useDispatch();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const userRole = localStorage.getItem("userRole")

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllFacilityTypes(payload,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setData(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])


    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const selectedCodes = data.map((row) => row.facility_type_code);
            setSelectedRows(selectedCodes);
        } else {
            setSelectedRows([]);
        }
        setSelectAll(event.target.checked);
    };

    const handleCheckboxChange = (facilityCode) => {
        const isSelected = selectedRows.includes(facilityCode);
        let updatedSelectedRows = [];
        if (isSelected) {
            updatedSelectedRows = selectedRows.filter((code) => code !== facilityCode);
        } else {
            updatedSelectedRows = [...selectedRows, facilityCode];
        }
        setSelectedRows(updatedSelectedRows);
        setSelectAll(updatedSelectedRows.length === data.length);
    };

    // const handleCheckboxChange = (facilityCode) => {
    //     const isSelected = selectedRows.includes(facilityCode);
    //     setSelectedRows((prevSelected) =>
    //         isSelected
    //             ? prevSelected.filter((code) => code !== facilityCode)
    //             : [...prevSelected, facilityCode]
    //     );
    // };

    const handleEditClose = () => {
        setAnchorEl(null);
    };


    const handleDelete = () => {
        onDelete(selectedRows);
        setSelectedRows([]);
    };

    useEffect(() => {
        setPage(1)
    }, [searchFacilityCode, searchFacilityName])

    const filteredData = data.filter((row) => {
        const matchesFacilityCode = row.facility_type_code.toLowerCase().includes(searchFacilityCode.toLowerCase());
        const matchesFacilityName = row.facility_type_Name.toLowerCase().includes(searchFacilityName.toLowerCase());
        return matchesFacilityCode && matchesFacilityName;
    });

    const handleClearSearch = () => {
        setSearchFacilityCode('');
        setSearchFacilityName('');
        setSearchFacilityType('');
    };

    const handleAddNew = () => {
        setShowModal(true);
    }


    const handleCloseEditModal = () => {
        setEditModalOpen(false);

    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNewFacilityCode('');
        setNewFacilityName('');
    };

    const handleEditClick = (event, selectedFacility) => {
        setAnchorEl(event.currentTarget);
        setSelectedFacility(selectedFacility);
        // Prefill the edit facility data
        setEditFacilityCode(selectedFacility.facility_type_code);
        setEditFacilityName(selectedFacility.facility_type_Name);
        setId(selectedFacility.id)
        setEditModalOpen(true);
    };


    const handleSaveNewFacility = () => {
        // Save the new facility data here...
        // You can add validations if required.

        // Clear the text fields and close the modal
        setNewFacilityCode('');
        setNewFacilityName('');
        setShowModal(false);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleAddNewFacilityType = (e) => {
        e.preventDefault();

        if (!newFacilityName || !newFacilityCode) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            facility_type_Name: newFacilityName,
            facility_type_code: newFacilityCode,
        };

        setLoading(true);
        dispatch(
            addFacilityType(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("State Master added successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setShowModal(false);
                    setUpdated(prevState => !prevState);
                    setNewFacilityCode("")
                    setNewFacilityName("")
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleUpdateBiller = (e) => {
        e.preventDefault();

        if (!editFacilityName || !editFacilityCode) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            facility_type_Name: editFacilityName,
            facility_type_code: editFacilityCode,
            facilitytype_id: id,
        };

        setLoading(true);
        dispatch(
            updateFacilityType(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("State Master updated successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setEditModalOpen(false);
                    setUpdated(prevState => !prevState);

                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleDeleteBiller = (id) => {
        console.log(id)
        const payload = {
            facilitytype_id: id
        };

        setLoading(true);
        dispatch(
            deleteFacilityType(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Facilty Type deleted successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    // setEditModalOpen(false);
                    setUpdated(prevState => !prevState);
                    setOpenDeleteModal(false)


                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleDeleteModal = (id) => {
        setOpenDeleteModal(true);
        setId(id)
    };

    const downloadSelectedRows = () => {
        // Filter rows based on selectedRows array
        const exportData = filteredData
            .filter(row => selectedRows.includes(row.facility_type_code))
            .map(row => ({
                'Facility Type Code': row.facility_type_code,
                'Facility Type Name': row.facility_type_Name,
            }));

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(exportData);
        const columnWidths = [
            { wch: 15 }, // Width of Date column
            { wch: 40 }, // Width of Provider column

        ];

        ws['!cols'] = columnWidths;

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

        // Save the workbook as an Excel file
        XLSX.writeFile(wb, 'Facility Type.xlsx');
    };

    return (
        <Box sx={{ px: 10, py: 2 }}>

            <DeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                loading={loading}
                setLoading={setLoading}
                handleDeletefunction={handleDeleteBiller}
                id={id}
            />
            <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', mb: 2 }}>Search Facility Type Master</Typography>
            <Grid container spacing={5} sx={{}}>
                <Grid item md={3} xs={6}>
                    <InputLabel>Facility Type Code</InputLabel>
                    <CustomTextField
                        value={searchFacilityCode}
                        setValue={setSearchFacilityCode}
                    />
                </Grid>
                <Grid item md={3} xs={6}>
                    <InputLabel>Facility Type Name</InputLabel>
                    <CustomTextField
                        value={searchFacilityName}
                        setValue={setSearchFacilityName}
                    />
                </Grid>
                <Grid item md={6} xs={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', verticalAlign: 'middle', py: 3, }}>
                        <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleClearSearch}
                            sx={{ background: '#0093AF !important', boxShadow: 'none !important', mr: 2 }}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={userRole !== "Admin"}
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleAddNew}
                            sx={{ background: userRole !== "Admin" ? "#899499" : '#0093AF', boxShadow: 'none !important', mr: 2 }}
                        >
                            Add New
                        </Button>
                        {/* <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleDelete}
                            disabled={selectedRows.length === 0}
                            sx={{ background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', boxShadow: 'none !important',mr:2 }}
                        >
                            Delete
                        </Button> */}
                        <Button
                            disabled={selectedRows.length === 0}
                            variant="contained"
                            onClick={downloadSelectedRows}
                            sx={{ boxShadow: 'none !important', background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', }}
                        >
                            Download Excel
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {
                loading ? (
                    <Box sx={{ minHeight: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress sx={{ color: '#0093AF !important' }} />
                    </Box>
                ) : (

                    filteredData.length === 0 ? (
                        <Typography variant="body2" sx={{ textAlign: 'center', color: '#555', mt: 15, }}>
                            No Data Available
                        </Typography>
                    ) : (
                        <>
                            <TableContainer component={Paper} className='scrollBar'>
                                <Table>
                                    <TableHead sx={{ backgroundColor: '#0093AF', color: '#fff' }}>
                                        <TableRow>
                                            <TableCell sx={{ color: '#fff', py: 0 }}>
                                                <Checkbox
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                    style={{ color: '#fff', }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ color: '#fff', py: 1 }}>Facility Type Code</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 1 }}>Facility Type Name</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 1 }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row) => (
                                            <TableRow key={row.facility_type_code}>
                                                <TableCell sx={{ py: 0.3 }}>
                                                    <Checkbox
                                                        size="small"
                                                        checked={selectedRows.includes(row.facility_type_code)}
                                                        onChange={() => handleCheckboxChange(row.facility_type_code)}
                                                        style={{ color: '#0093AF' }}
                                                    />
                                                </TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3 }}>{row.facility_type_code}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3 }}>{row.facility_type_Name}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3 }}>
                                                    <IconButton disabled={userRole !== "Admin"} color="primary" onClick={(e) => handleEditClick(e, row)}>
                                                        <EditIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                    </IconButton>
                                                    <IconButton disabled={userRole !== "Admin"} color="secondary" onClick={(e) => handleDeleteModal(row.id)}>
                                                        <DeleteIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <CustomPagination
                                count={Math.ceil(filteredData.length / rowsPerPage)}
                                page={page}
                                onChange={handleChangePage}
                            />
                        </>
                    ))}

            {/* Modal for adding new facility */}
            <Modal open={showModal} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 10, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Add New Facilty Type</Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>

                    <InputLabel sx={{ mt: 2 }}>Facility Type Code</InputLabel>
                    <CustomTextField

                        value={newFacilityCode}
                        setValue={setNewFacilityCode}



                    />
                    <InputLabel sx={{ mt: 2 }}>Facility Type Name</InputLabel>
                    <CustomTextField
                        label="Facility Type Name"
                        value={newFacilityName}
                        setValue={setNewFacilityName}
                        fullWidth
                        margin="normal"
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        {/* <Button variant="contained" disableElevation onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                            Cancel
                        </Button> */}
                        <Button variant="contained" disableElevation onClick={handleAddNewFacilityType} color="primary">
                            {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "save"}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal open={editModalOpen} onClose={handleCloseEditModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 10, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Edit Facility</Typography>
                        <IconButton onClick={handleCloseEditModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <InputLabel sx={{ mt: 2 }}>Facility Type Code</InputLabel>
                    <CustomTextField
                        value={editFacilityCode}
                        setValue={setEditFacilityCode}
                    />
                    <InputLabel sx={{ mt: 2 }}>Facility Type Name</InputLabel>
                    <CustomTextField
                        value={editFacilityName}
                        setValue={setEditFacilityName}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        {/* <Button variant="contained" disableElevation onClick={handleCloseEditModal} color="primary" sx={{ mr: 2 }}>
                            Cancel
                        </Button> */}
                        <Button variant="contained" disableElevation onClick={handleUpdateBiller} color="primary">
                            {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Update"}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <CustomSnackbar
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </Box>
    );
};

export default FacilityTypeMasterBody;

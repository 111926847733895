import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Toolbar, IconButton, Hidden, Avatar, Menu, MenuItem, Collapse } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../Image/Logo.png'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useDispatch } from 'react-redux';
import { getProviderGraph } from '../../../api/action';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(4)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'right',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const Routes = [
    {
        name: 'Billing Report',
        icon: <SummarizeIcon style={{ width: 24, height: 24 }} />,
        link: '/biller-dashboard'
    },
    {
        name: 'Timesheet',
        icon: <ListAltIcon style={{ width: 24, height: 24 }} />,
        link: '/biller/timesheet'
      },

];



export default function BillerSidebar({ children }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const location = useLocation();
    const pathname = location.pathname;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMasters, setOpenMasters] = React.useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState('');
    const dispatch = useDispatch();
  const id = localStorage.getItem("userId")


    React.useEffect(() => {
        setLoading(true)
        const payload = {
          start_date: null,
          end_date: null,
          user_id: id
        };
        dispatch(
          getProviderGraph(payload,
            (data) => {
              setLoading(false);
    
            },
            (error) => {
              console.log(error?.response?.data?.code)
              if (error?.response?.data?.code === "token_not_valid") {
                handleLogout()
              }
              setLoading(false)
            }
          )
        );
    
      },[])

    React.useEffect(() => {
        if (open === false) {
            setOpenMasters(false);
        }
    }, [open]);

    // const history = useHistory()
    const handleLinkClick = (link) => {
        navigate(link);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    const handleMastersClick = () => {
        setOpenMasters((prevOpenMasters) => !prevOpenMasters);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{ background: '#fff' }} elevation={1}>
                <Toolbar>
                    {/* <Box sx={{borderRight:'1px solid #000'}}> */}
                    <IconButton
                        color="#0093AF"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* </Box> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100vw' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', verticalAlign: 'middle' }}>
                            <img src={Logo} height={"50px"} width={"150px"} />
                            <Typography sx={{ px: 1, py: 1 }} variant="h6" noWrap component="div" color="#0093AF" fontWeight="bold">
                                {/* Billing Portal */}
                            </Typography>

                        </Box>
                        <Box >
                            <IconButton onClick={handleClick} disableRipple sx={{ cursor: 'default' }}>
                                <Avatar
                                    alt="Profile Picture"
                                    // src={/* Provide a valid image source here */}
                                    sx={{
                                        height: { md: 24, xs: 20 },
                                        width: { md: 24, xs: 20 },
                                        p: { xs: 0 }
                                    }}
                                />
                                <Typography className="fontSans fw500 fs14 mHide" sx={{ px: 1 }}>
                                    {localStorage.getItem("userName")}
                                </Typography>
                                {/* {anchorEl ? <KeyboardArrowUpIcon className="iconLightColor mHide" /> : <KeyboardArrowDownRoundedIcon className="iconLightColor mHide" />} */}
                            </IconButton>

                            {/* <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <MenuItem onClick={handleClose}>Profile</MenuItem>
                                <MenuItem onClick={handleClose}>My Account</MenuItem>

                            </Menu> */}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton disableRipple onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>

                </DrawerHeader>
                <Divider />
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '85vh' }}>
                    <Box>
                        {open ? (
                            Routes.map((route, index) => (
                                <Box key={index} style={{ cursor: 'pointer' }}>
                                    {route.submenu ? (
                                        <>
                                            <Button
                                                onClick={handleMastersClick}
                                                sx={{
                                                    textAlign: 'left',
                                                    backgroundColor: pathname === "" ? '#0093AF' : 'transparent',
                                                    color: pathname === "" ? '#fff' : '#0093AF',
                                                    textAlign: 'left',
                                                    width: '80%',
                                                    mx: 3,
                                                    alignItems: 'left',
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                    my: 1.2,
                                                    py: 1,
                                                    fontWeight: 'bold',
                                                    '&:hover': {
                                                        backgroundColor: '#0093AF',
                                                        color: '#fff',
                                                    },
                                                }}
                                            >
                                                {route.icon}
                                                <Typography className="fs16" sx={{ px: 1, textTransform: 'none', textAlign: 'left' }}>{route.name}</Typography>
                                                {openMasters ? <ExpandLess sx={{ ml: 4 }} /> : <ExpandMore sx={{ ml: 4 }} />}
                                            </Button>
                                            <Collapse in={openMasters} timeout="auto">
                                                <Box sx={{ pl: 3 }}>
                                                    {route.submenu.map((subitem, index) => (
                                                        <Button
                                                            sx={{
                                                                textAlign: 'left',
                                                                backgroundColor: pathname === subitem.link ? '#0093AF' : 'transparent',
                                                                color: pathname === subitem.link ? '#fff' : '#0093AF',
                                                                textAlign: 'left',
                                                                width: '80%',
                                                                mx: 3,
                                                                alignItems: 'left',
                                                                display: 'flex',
                                                                justifyContent: 'left',
                                                                my: 1.2,
                                                                py: 1,
                                                                fontWeight: 'bold',
                                                                '&:hover': {
                                                                    backgroundColor: '#0093AF',
                                                                    color: '#fff',
                                                                },
                                                                textTransform: 'none'
                                                            }}
                                                            onClick={() => handleLinkClick(subitem.link)}
                                                            key={index}
                                                        >
                                                            <Typography className="fs16">{subitem.name}</Typography>
                                                        </Button>
                                                    ))}
                                                </Box>
                                            </Collapse>
                                        </>
                                    ) : (
                                        <Button
                                            onClick={() => handleLinkClick(route.link)}
                                            sx={{
                                                textAlign: 'left',
                                                backgroundColor: pathname === route.link ? '#0093AF' : 'transparent',
                                                color: pathname === route.link ? '#fff' : '#0093AF',
                                                textAlign: 'left',
                                                width: '80%',
                                                mx: 3,
                                                alignItems: 'left',
                                                display: 'flex',
                                                justifyContent: 'left',
                                                my: 1.2,
                                                py: 1,
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    backgroundColor: '#0093AF',
                                                    color: '#fff',
                                                },
                                            }}
                                        >
                                            {route.icon}
                                            <Typography className="fs16" sx={{ px: 1, textTransform: 'none', textAlign: 'left' }}>{route.name}</Typography>
                                        </Button>
                                    )}
                                </Box>
                            ))
                        ) : (
                            Routes.map((route, index) => (
                                <Box key={index} style={{ cursor: 'pointer' }}>
                                    {route.submenu ? (
                                        <>
                                            <Box style={{ cursor: 'pointer' }} key={index} onClick={handleMastersClick}>
                                                {/* <Link to={route.link} > */}

                                                <Box
                                                    sx={{
                                                        width: 36, height: 36,
                                                        backgroundColor: pathname === route.link ? '#0093AF' : 'transparent',
                                                        color: pathname === route.link ? '#fff' : '#0093AF',
                                                        mx: 1,
                                                        my: 1.5,
                                                        borderRadius: '6px',
                                                        p: 0.8,
                                                    }}
                                                    onClick={() => setOpen(true)}
                                                >
                                                    {route.icon}
                                                </Box>
                                                {/* </Link> */}

                                            </Box>
                                            <Collapse in={openMasters} timeout="auto">
                                                <Box sx={{ pl: 3 }}>
                                                    {route.submenu.map((subitem, index) => (
                                                        <Button
                                                            sx={{
                                                                textAlign: 'left',
                                                                backgroundColor: pathname === subitem.link ? '#0093AF' : 'transparent',
                                                                color: pathname === subitem.link ? '#fff' : '#0093AF',
                                                                textAlign: 'left',
                                                                width: '80%',
                                                                mx: 3,
                                                                alignItems: 'left',
                                                                display: 'flex',
                                                                justifyContent: 'left',
                                                                my: 1.2,
                                                                py: 1,
                                                                fontWeight: 'bold',
                                                                '&:hover': {
                                                                    backgroundColor: '#0093AF',
                                                                    color: '#fff',
                                                                },
                                                                textTransform: 'none'
                                                            }}
                                                            onClick={() => handleLinkClick(subitem.link)}
                                                            key={index}
                                                        >
                                                            <Typography className="fs16">{subitem.name}</Typography>
                                                        </Button>
                                                    ))}
                                                </Box>
                                            </Collapse>
                                        </>
                                    ) : (
                                        <Link to={route.link} key={index}>
                                            <Box style={{ cursor: 'pointer' }}>
                                                <Box
                                                    sx={{
                                                        width: 36, height: 36,
                                                        backgroundColor: pathname === route.link ? '#0093AF' : 'transparent',
                                                        color: pathname === route.link ? '#fff' : '#0093AF',
                                                        mx: 1,
                                                        my: 1.5,
                                                        borderRadius: '6px',
                                                        p: 0.8,
                                                    }}
                                                >
                                                    {route.icon}
                                                </Box>
                                            </Box>
                                        </Link>
                                    )}
                                </Box>
                            ))
                        )}
                        {/* <Divider /> */}

                    </Box>
                    <Box>
                        {
                            open ?
                                <Button
                                    sx={{
                                        textAlign: 'left',
                                        backgroundColor: '#0093AF',
                                        color: '#fff',
                                        textAlign: 'left',
                                        width: '80%',
                                        mx: 3,
                                        // my: 4,
                                        // mt: "100%",
                                        alignItems: 'left',
                                        display: 'flex',
                                        justifyContent: 'left',
                                        py: 1,
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            backgroundColor: '#0093AF',
                                            color: '#fff',
                                        },
                                    }}
                                    onClick={() => handleLogout()}
                                >
                                    <LogoutIcon style={{ width: 24, height: 24 }} />
                                    <Typography className="fs16" sx={{ px: 2, textTransform: 'none', display: open ? 'block' : 'none', }}>Logout</Typography>
                                </Button>
                                :
                                <LogoutIcon sx={{
                                    width: 36, height: 36,
                                    backgroundColor: '#0093AF',
                                    color: '#fff',
                                    m: 1,
                                    borderRadius: '6px',
                                    p: 1,
                                }}
                                    onClick={() => handleLogout()}
                                />


                        }
                    </Box>
                </Box>




            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, width: !open ? "95vw" : '80vw', pt: 10 }}>
                {children}
            </Box>
        </Box>
    );
}
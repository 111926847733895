import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Box } from '@mui/material';
import {
    Autocomplete,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    InputLabel,
    Grid,
    IconButton,
    Menu,
    CircularProgress
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAllFacility, getAllFacilityTypes, getAllProviders, getFacilityByProviderId, getReportByProvider, getSelectedCopyData, getSuperBillByAdmin } from '../api/action';
import { useDispatch } from 'react-redux';
import CustomPagination from '../Utils/CustomPagination';
import * as XLSX from 'xlsx';
import CustomTextField from '../Utils/CustomTextfield';
import CloseIcon from '@mui/icons-material/Close';
const CopyDataModal = ({ open, setOpen, copyData, setCopyData, setFilteredData, filteredData }) => {

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        handleClearSearch()
    }, [open]);

    useEffect(() => {
        setSelectedRows([])
    }, []);

    const [selectedRows, setSelectedRows] = useState([]);
    const [searchFacilityCode, setSearchFacilityCode] = useState('');
    const [searchFacilityName, setSearchFacilityName] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [updated, setUpdated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [providerList, setProviderList] = useState([]);
    const [facilityList, setFacilityList] = useState([]);
    const [facilityTypeList, setFacilityTypeList] = useState([]);
    const [searchProvider, setSearchProvider] = useState(null);
    const [searchFacility, setSearchFacility] = useState(null);
    const [searchFacilityType, setSearchFacilityType] = useState(null);
    const providerId = localStorage.getItem("userId")
    const profileId = localStorage.getItem("profileId")
    const name = localStorage.getItem("userName")
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [searchFirstName, setSearchFirstName] = useState('');
    const [searchLastName, setSearchLastName] = useState('');
    const [status, setStatus] = useState('');
    // const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState(false);
    const [counts, setCounts] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const ROWS_PER_PAGE = 50;
    const [previousPayload, setPreviousPayload] = useState(null)

    const formatDate = (date) => {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        const matchingProvider = providerList.find(provider =>
            `${provider.First_Name} ${provider.Last_Name}` === name
        );
        if (matchingProvider) {
            setSearchProvider(matchingProvider);
        }
    }, [providerList, name]);

    const dispatch = useDispatch();

    const [startDate, endDate] = dateRange;

    const handleCheckboxChange = (facilityCode) => {
        const isSelected = selectedRows.includes(facilityCode);
        setSelectedRows((prevSelected) =>
            isSelected
                ? prevSelected.filter((code) => code !== facilityCode)
                : [...prevSelected, facilityCode]
        );
    };

    const handleEditClick = (event, selectedFacility) => {
        setAnchorEl(event.currentTarget);
        setSelectedFacility(selectedFacility);
    };

    const handleEditClose = () => {
        setAnchorEl(null);
    };

    // useEffect(() => {
    //     setFilteredData([])
    // }, [])

    useEffect(() => {
        handleSearch()
        setSearch(true)
    }, [updated, currentPage])

    const handleCopyData = () => {
        const payload = {
            "super_biller_ids": selectedRows
        }

        dispatch(
            getSelectedCopyData(payload,
                (data) => {
                    setLoading(false);
                    // console.log(data?.data, "data")
                    setData(data?.data)
                    setSelectedRows([])
                    handleClose()
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );
    };

    const handleSearch = () => {
        setFilteredData([])
        // setSelectAll(false)

        if (!searchFacility && !searchFirstName && !searchLastName && !status && !startDate && !endDate && search === true) {
            setSnackbarMessage("Please fill the above field");
            setSnackbarOpen(true);
            setSnackbarSeverity("error");
        } else {

            setLoading(true)
            const payload = {
                "facility_name": searchFacility?.id,
                "first_name": searchFirstName,
                "last_name": searchLastName,
                "status": status,
                "date_from": startDate ? formatDate(startDate) : null,
                "date_to": endDate ? formatDate(endDate) : null,

                "provider_id": searchFacility || endDate || startDate ? profileId : "",
                "download_excel": false

            }

            dispatch(
                getReportByProvider(payload, currentPage,
                    (data) => {
                        setLoading(false);
                        setFilteredData(search === true ? data?.data?.results : []);
                        setCounts(data?.data?.count)
                        setSelectAll(false)
                        // setSelectedRows([])
                    },
                    (error) => {
                        console.log(error)
                        setLoading(false)
                    }
                )
            );

        }
    };

    useEffect(() => {
        setCurrentPage(1)

    }, [searchFacility, searchFirstName, searchLastName, startDate, endDate, status])

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllProviders(payload,
                (data) => {
                    setLoading(false);
                    setProviderList(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllFacilityTypes(payload,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setFacilityTypeList(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getFacilityByProviderId(payload, providerId,
                (data) => {
                    setLoading(false);
                    setFacilityList(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    const handleEdit = (newStatus) => {
        if (selectedFacility) {
            const updatedFacility = {
                ...selectedFacility,
                status: newStatus,
            };

            const updatedData = data.map((facility) =>
                facility.facilityCode === selectedFacility.facilityCode ? updatedFacility : facility
            );

            setData(updatedData);
            //   onEdit(updatedFacility);
            setAnchorEl(null);
        }
    };



    const handleDelete = () => {
        //   onDelete(selectedRows);
        setSelectedRows([]);
    };


    // useEffect(() => {
    //     setCurrentPage(1)
    // }, [searchProvider, searchFacility, searchFacility, searchFacilityType, searchFirstName, searchLastName])


    const handleClearSearch = () => {
        setSearchFirstName("")
        setSearchLastName("")
        setDateRange([null, null])
        setSearchFacility(null);
        setSearchFacilityType(null);
    };

    const handleSelectAllChange = (event) => {
        setSelectAll(event.target.checked);
        if (event.target.checked) {
            const allCodes = filteredData.map((row) => row.id);
            setSelectedRows(allCodes);

        } else {
            setSelectedRows([]);
        }
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;

    // const handleDownloadExcel = () => {
    //     const exportData = filteredData
    //         .filter(row => selectedRows.includes(row.id))
    //         .map(row => ({
    //             'Facility Type': row.facility_type?.facility_type_Name,
    //             'State': row.state?.state_Name,
    //             'Facility Name': row.facility_master?.facility_name,
    //             'Provider Email': "",
    //             'Provider First Name': row.provider_profile?.First_Name,
    //             'Provider Last Name': row.provider_profile?.Last_Name,

    //             'Patient First Name': row.patient_name,
    //             'Patient Last Name': row.last_name,
    //             'Date of Birth': row.dob,
    //             'Date of Service': row.date_of_service,
    //             'CPT Code': row.cpt_code?.visit_reason_code,
    //             'DX Code': row.dx_code,
    //             'Modifier': row.modifier,
    //             'Admission': row.admission,
    //             'Comment': row.comment,
    //             'Reason for Visit': row.reason_for_visit,
    //             'Submitted On': "",
    //             'Time': "",
    //         }));

    //     const wb = XLSX.utils.book_new();

    //     const ws = XLSX.utils.json_to_sheet(exportData);

    //     const columnWidths = [
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 },
    //         { wch: 20 }
    //     ];

    //     ws['!cols'] = columnWidths;

    //     // Add worksheet to workbook
    //     XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

    //     // Save the workbook as an Excel file
    //     XLSX.writeFile(wb, 'Reports.xlsx');
    // };

    function convertDateString(dateString) {
        // Create a Date object from the date string
        const date = new Date(dateString);

        // Extract the month, day, and year
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        // Format the date as MM/DD/YYYY
        return `${month}/${day}/${year}`;
    }

    // console.log(filteredData, "filter")
    console.log(selectedRows, "filter")

    useEffect(() => {
        // setCopyData([])

        const copyFilteredData = data;
        const mappedData = copyFilteredData?.map(row => ({
            provider_profile: row.provider_profile?.id,
            facility_master: "",
            patient_name: row.patient_name,
            last_name: row.last_name,
            dob: row.dob,
            date_of_service: row.date_of_service,
            cpt_code: row.cpt_code?.id,
            dx_code: row.dx_code.split(", "),
            admission: row.admission,
            reason_for_visit: row.reason_for_visit,
            comment: row.comment,
            modifier: row.modifier,
            // state: row.state,
            facility_type: "",
            // state: row.state,
            // facility_type: row.facility_type,
        }));
        setCopyData(mappedData)

    }, [selectedRows, data])

    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    className="scrollBar-Y scrollBar"
                    style={{
                        backgroundColor: '#fff',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        padding: '20px',
                        outline: 'none',
                        minHeight: '90vh',
                        maxHeight: '90vh',
                        width: '90vw',
                        maxWidth: '90vw',
                        borderRadius: '16px',
                        overflow: 'auto',   // Enable scrolling
                    }}
                >
                    <Box sx={{ px: 10, py: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                            <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold' }}>Search (Copy Data)</Typography>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Grid container spacing={2} sx={{ py: 4 }}>
                            {/* <Grid item md={2} xs={6}>
                    <InputLabel id="provider-label">Provider</InputLabel>
                    <Autocomplete
                        disabled
                        className='scrollBar'
                        fullWidth
                        value={searchProvider}
                        onChange={(event, newValue) => {
                            setSearchProvider(newValue);
                        }}
                        // sx={{ width: '15vw !important' }}
                        clearIcon={null}
                        options={providerList}
                        getOptionLabel={(option) => option.First_Name + " " + option.Last_Name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{ '& input': { height: '0px', fontSize: '14px', } }}
                            />
                        )}
                    />
                </Grid> */}
                            <Grid item md={2.4} xs={6}>
                                <InputLabel id="facility-name-label">Facility Name</InputLabel>
                                <Autocomplete
                                    className='scrollBar'
                                    fullWidth
                                    value={searchFacility}
                                    onChange={(event, newValue) => {
                                        setSearchFacility(newValue);
                                    }}
                                    // sx={{ width: '15vw !important' }}
                                    clearIcon={null}
                                    options={facilityList} // Pass the array directly
                                    getOptionLabel={(option) => option.facility_name}
                                    getOptionSelected={(option, value) => option.facility_name === value.facility_name}
                                    isOptionEqualToValue={(option, value) => option.facility_name === value.facility_name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ '& input': { height: '0px', fontSize: '14px' } }}
                                        />
                                    )}
                                />
                            </Grid>


                            <Grid item md={2.4} xs={6}>
                                <InputLabel>Patient First Name</InputLabel>
                                <CustomTextField
                                    value={searchFirstName}
                                    setValue={setSearchFirstName}
                                />
                            </Grid>

                            <Grid item md={2.4} xs={6}>
                                <InputLabel>Patient Last Name</InputLabel>
                                <CustomTextField
                                    value={searchLastName}
                                    setValue={setSearchLastName}
                                />
                            </Grid>

                            <Grid item md={2.4} xs={6}>
                                <InputLabel id="facility-type-label">Date of Service (From)</InputLabel>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setDateRange([date, endDate])}
                                    isClearable={true}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    className='reactDateRangePicker3'
                                />
                            </Grid>

                            <Grid item md={2.4} xs={6}>
                                <InputLabel id="facility-type-label">Date of Service (To)</InputLabel>
                                <DatePicker
                                    // placeholderText='Select End Date'
                                    selected={endDate}
                                    onChange={(date) => setDateRange([startDate, date])}
                                    isClearable={true}
                                    className='reactDateRangePicker3'
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                />
                            </Grid>

                            {/* <Grid item md={2.4} xs={6}>
                   
                    <InputLabel id="facility-type-label">Facility Type</InputLabel>
                    <Autocomplete
                        className='scrollBar'
                        fullWidth
                        value={searchFacilityType}
                        onChange={(event, newValue) => {
                            setSearchFacilityType(newValue);
                        }}
                        sx={{ width: '15vw !important' }}
                        clearIcon={null}
                        options={facilityTypeList}
                        getOptionLabel={(option) => option.facility_type_Name}
                        getOptionSelected={(option, value) => option.facility_type_Name}
                        isOptionEqualToValue={(option, value) => option.facility_type_Name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{ '& input': { height: '0px', fontSize: '14px', } }}
                            />
                        )}
                    />
                </Grid> */}

                            {/* <Grid item md={2.4} xs={6}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    fullWidth
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    sx={{ height: '32px', padding: '5px 10px', }}
                                >
                                    <MenuItem value={""}>
                                        All
                                    </MenuItem>
                                    {['Pending', 'Confirmed'].map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </Grid> */}
                        </Grid>

                        <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', }}>
                            <Button
                                className='buttonPadding'
                                variant="contained"
                                onClick={handleClearSearch}
                                sx={{ background: '#0093AF !important', boxShadow: 'none !important', mr: 1 }}
                            >
                                Clear
                            </Button>

                            <Button
                                className='buttonPadding'
                                variant="contained"
                                onClick={handleSearch}
                                sx={{ background: '#0093AF !important', boxShadow: 'none !important', }}
                            >
                                Search
                            </Button>
                        </Box>

                        {loading ? (
                            <Box sx={{ minHeight: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress sx={{ color: '#0093AF !important' }} />
                            </Box>
                        ) :
                            filteredData?.length === 0 ? (
                                <Typography variant="body2" sx={{ textAlign: 'center', color: '#555', mt: 15, }}>
                                    No Data Available
                                </Typography>
                            ) :
                                (
                                    <Box sx={{ my: 4 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                                            <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', }}>Data</Typography>
                                            {/* <Button
                                                sx={{
                                                    float: 'right',
                                                    boxShadow: 'none !important',
                                                    background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important',

                                                }}
                                                variant="contained"
                                                onClick={handleDownloadExcel}
                                                disabled={selectedRows.length === 0}
                                            >
                                                Download Excel
                                            </Button> */}

                                            <Button
                                                sx={{
                                                    float: 'right',
                                                    boxShadow: 'none !important',
                                                    background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important',
                                                    float: 'right',
                                                    mt: 2
                                                }}
                                                variant="contained"
                                                onClick={handleCopyData}
                                                disabled={selectedRows.length === 0}
                                            >
                                                Copy Data
                                            </Button>
                                        </Box>

                                        <TableContainer className='scrollBar table-container' component={Paper}>
                                            <Table className='custom-table  '>
                                                <TableHead sx={{ backgroundColor: '#0093AF', }}>
                                                    <TableRow>
                                                        <TableCell sx={{ color: '#fff', py: 0.5 }}>
                                                            {/* <Checkbox
                                                                checked={selectAll}
                                                                onChange={handleSelectAllChange}
                                                                style={{ color: '#fff' }}
                                                            /> */}
                                                        </TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Provider</TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Facility</TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Facility Type</TableCell>
                                                        {/* <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >State</TableCell> */}
                                                        <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >First Name</TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Last Name</TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Date of Birth</TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Date of Service</TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >CPT Code</TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >DX Code</TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Admission</TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Reason for Visit</TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Comment</TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Modifier</TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1 }} >Status</TableCell>
                                                        <TableCell sx={{ color: "#fff", py: 1 }} >Biller</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(filteredData ? filteredData.reverse() : []).map((row, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell sx={{ py: 0.5 }}>
                                                                <Checkbox
                                                                    checked={selectedRows.includes(row.id)}
                                                                    onChange={() => handleCheckboxChange(row.id)}
                                                                    style={{ color: '#0093AF' }}
                                                                />
                                                            </TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.provider_profile?.First_Name + " " + row.provider_profile?.Last_Name}</TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.facility_master?.facility_name}</TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.facility_type?.facility_type_Name}</TableCell>
                                                            {/* <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.state?.state_Name}</TableCell> */}
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.patient_name}</TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.last_name}</TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.dob}</TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.date_of_service}</TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.cpt_code?.visit_reason_code}</TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.dx_code}</TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.admission}</TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.reason_for_visit}</TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.comment}</TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.modifier}</TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }} >{row?.is_status}</TableCell>
                                                            <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }} >{row?.biller_profile !== null ? row?.biller_profile?.First_Name + " " + row?.biller_profile?.Last_Name : ""}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <CustomPagination
                                            count={Math.ceil(counts / ROWS_PER_PAGE)}
                                            page={currentPage}
                                            onChange={handleChangePage}
                                        />


                                    </Box>
                                )}

                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleEditClose}
                        >
                            <MenuItem onClick={() => handleEdit('Active')}>Active</MenuItem>
                            <MenuItem onClick={() => handleEdit('Inactive')}>Inactive</MenuItem>
                        </Menu>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default CopyDataModal;

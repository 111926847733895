import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Checkbox,
    Grid,
    Typography,
    Box,
    Button,
    Modal,
    InputLabel,
    CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomTextField from '../Utils/CustomTextfield';
import CustomPagination from '../Utils/CustomPagination';
import { useDispatch } from 'react-redux';
import { addVisitReason, deleteVisitReason, getAllVisitReasons, updateVisitReason } from '../api/action';
import CustomSnackbar from '../Utils/CustomSnackbar';
import DeleteModal from '../Utils/DeleteModal';
import * as XLSX from 'xlsx';
import CloseIcon from '@mui/icons-material/Close';

const VisitReasonBody = ({ onEdit, onDelete }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchVisitReasonCode, setSearchVisitReasonCode] = useState('');
    const [searchVisitReasonName, setSearchVisitReasonName] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedVisitReason, setSelectedVisitReason] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editVisitReasonCode, setEditVisitReasonCode] = useState('');
    const [editVisitReasonName, setEditVisitReasonName] = useState('');
    const [newVisitReasonCode, setNewVisitReasonCode] = useState('');
    const [newVisitReasonName, setNewVisitReasonName] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState('');
    const [updated, setUpdated] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [id, setId] = useState('');
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const dispatch = useDispatch();
    const userRole = localStorage.getItem("userRole")

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllVisitReasons(payload,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setData(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    const handleCheckboxChange = (visitReasonCode) => {
        const isSelected = selectedRows.includes(visitReasonCode);
        setSelectedRows((prevSelected) =>
            isSelected
                ? prevSelected.filter((code) => code !== visitReasonCode)
                : [...prevSelected, visitReasonCode]
        );
    };

    const handleEditClick = (event, selectedVisitReason) => {
        setAnchorEl(event.currentTarget);
        setSelectedVisitReason(selectedVisitReason);
        // Prefill the edit visit reason data
        setEditVisitReasonCode(selectedVisitReason.visit_reason_code);
        setEditVisitReasonName(selectedVisitReason.visit_reason_description);
        setId(selectedVisitReason.id);
        setEditModalOpen(true);
    };



    const handleDelete = () => {
        onDelete(selectedRows);
        setSelectedRows([]);
    };

    useEffect(() => {
        setPage(1)
    }, [searchVisitReasonCode, searchVisitReasonName])

    const filteredData = data.filter((row) => {
        const matchesVisitReasonCode = row.visit_reason_code.toLowerCase().includes(searchVisitReasonCode.toLowerCase());
        const matchesVisitReasonName = row.visit_reason_description.toLowerCase().includes(searchVisitReasonName.toLowerCase());
        return matchesVisitReasonCode && matchesVisitReasonName;
    });

    const handleClearSearch = () => {
        setSearchVisitReasonCode('');
        setSearchVisitReasonName('');
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const selectedCodes = data.map((row) => row.visit_reason_code);
            setSelectedRows(selectedCodes);
        } else {
            setSelectedRows([]);
        }
        setSelectAll(event.target.checked);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleAddNewVisitReason = (e) => {
        e.preventDefault();

        if (!newVisitReasonCode || !newVisitReasonName) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            visit_reason_description: newVisitReasonName,
            visit_reason_code: newVisitReasonCode,
        };

        setLoading(true);
        dispatch(
            addVisitReason(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Reason for visit master added successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setShowModal(false);
                    setUpdated(prevState => !prevState);
                    setNewVisitReasonCode("")
                    setNewVisitReasonName("")
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleUpdateVisitReason = (e) => {
        e.preventDefault();
        if (!editVisitReasonName || !editVisitReasonCode) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }


        const payload = {
            visit_reason_description: editVisitReasonName,
            visit_reason_code: editVisitReasonCode,
            visitreason_id: id,
        };

        setLoading(true);
        dispatch(
            updateVisitReason(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Reason for Visit Master updated successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setEditModalOpen(false);
                    setUpdated(prevState => !prevState);

                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleDeleteVisitReason = (id) => {
        console.log(id)
        const payload = {
            visitreason_id: id
        };

        setLoading(true);
        dispatch(
            deleteVisitReason(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Reason for visit reason deleted successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    // setEditModalOpen(false);
                    setUpdated(prevState => !prevState);
                    setOpenDeleteModal(false)

                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "We are under maintenance. Please try later";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleDeleteModal = (id) => {
        setOpenDeleteModal(true);
        setId(id)
    };

    const downloadSelectedRows = () => {
        // Filter rows based on selectedRows array
        const exportData = filteredData
            .filter(row => selectedRows.includes(row.visit_reason_code))
            .map(row => ({
                'CPT Code': row.visit_reason_code,
                'CPT Description': row.visit_reason_description,
            }));

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(exportData);
        const columnWidths = [
            { wch: 15 }, // Width of Date column
            { wch: 40 }, // Width of Provider column

        ];

        ws['!cols'] = columnWidths;

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

        // Save the workbook as an Excel file
        XLSX.writeFile(wb, 'CPT.xlsx');
    };


    return (
        <Box sx={{ px: 10, py: 2 }}>
            <DeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                loading={loading}
                setLoading={setLoading}
                handleDeletefunction={handleDeleteVisitReason}
                id={id}
            />
            <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', mb: 2 }}>View CPT Codes</Typography>
            <Grid container spacing={5} >
                <Grid item md={3} xs={6}>
                    <InputLabel>CPT Code</InputLabel>
                    <CustomTextField
                        value={searchVisitReasonCode}
                        setValue={setSearchVisitReasonCode}
                    />
                </Grid>
                <Grid item md={3} xs={6}>
                    <InputLabel>CPT Description</InputLabel>
                    <CustomTextField
                        value={searchVisitReasonName}
                        setValue={setSearchVisitReasonName}
                    />
                </Grid>

                <Grid item md={6} xs={6} >
                    <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', verticalAlign: 'middle', py: 3, }}>
                        <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleClearSearch}
                            sx={{ background: '#0093AF !important', boxShadow: 'none !important', mr: 2 }}
                        >
                            Clear
                        </Button>
                        {/* <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleDelete}
                            disabled={selectedRows.length === 0}
                            sx={{ background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', boxShadow: 'none !important', mr: 2 }}
                        >
                            Delete
                        </Button> */}
                        <Button
                            disabled={userRole !== "Admin"}
                            className='buttonPadding'
                            variant="contained"
                            onClick={() => setShowModal(true)}
                            sx={{ background: userRole !== "Admin" ? "#899499" : '#0093AF', boxShadow: 'none !important', mr: 2 }}
                        >
                            Add New
                        </Button>
                        <Button
                            disabled={selectedRows.length === 0}
                            variant="contained"
                            onClick={downloadSelectedRows}
                            sx={{ boxShadow: 'none !important', background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', }}
                        >
                            Download Excel
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {
                loading ? (
                    <Box sx={{ minHeight: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                       <CircularProgress sx={{color:'#0093AF !important'}} />
                    </Box>
                ) : (

                    filteredData.length === 0 ? (
                        <Typography variant="body2" sx={{ textAlign: 'center', color: '#555', mt: 15 }}>
                            No Data Available
                        </Typography>
                    ) : (
                        <>
                            <TableContainer component={Paper} className='scrollBar'>
                                <Table>
                                    <TableHead sx={{ backgroundColor: '#0093AF', color: '#fff' }}>
                                        <TableRow>
                                            <TableCell sx={{ color: '#fff', py: 0.3 }}>
                                                <Checkbox
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                    style={{ color: '#fff', }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0.3 }}>CPT Code</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0.3 }}>CPT Description</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0.3 }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row) => (
                                            <TableRow key={row.visitReasonCode}>
                                                <TableCell sx={{ py: 0.3 }}>
                                                    <Checkbox
                                                        size="small"
                                                        checked={selectedRows.includes(row.visit_reason_code)}
                                                        onChange={() => handleCheckboxChange(row.visit_reason_code)}
                                                        style={{ color: '#0093AF' }}
                                                    />
                                                </TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3 }}>{row.visit_reason_code}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3 }}>{row.visit_reason_description}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.3 }}>
                                                    <IconButton disabled={userRole !== "Admin"} color="primary" onClick={(e) => handleEditClick(e, row)}>
                                                        <EditIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                    </IconButton>
                                                    <IconButton disabled={userRole !== "Admin"} color="secondary" onClick={() => handleDeleteModal(row.id)}>
                                                        <DeleteIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <CustomPagination
                                count={Math.ceil(filteredData.length / rowsPerPage)}
                                page={page}
                                onChange={handleChangePage}
                            />
                        </>
                    ))}

            {/* Modal for adding new visit reason */}
            <Modal open={showModal} onClose={() => setShowModal(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 10, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Add New CPT Code</Typography>
                        <IconButton onClick={() => setShowModal(false)} >
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <InputLabel sx={{ mt: 2 }}>CPT Code</InputLabel>
                    <CustomTextField
                        value={newVisitReasonCode}
                        setValue={setNewVisitReasonCode}
                    />
                    <InputLabel sx={{ mt: 2 }}>CPT Description</InputLabel>
                    <CustomTextField
                        value={newVisitReasonName}
                        setValue={setNewVisitReasonName}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button variant="contained" disableElevation onClick={handleAddNewVisitReason} color="primary">
                            {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Save"}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Modal for editing  visit reason */}
            <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 10, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Edit CPT Description</Typography>
                        <IconButton onClick={() => setEditModalOpen(false)} >
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <InputLabel sx={{ mt: 2 }}>CPT Code</InputLabel>
                    <CustomTextField value={editVisitReasonCode} setValue={setEditVisitReasonCode} />
                    <InputLabel sx={{ mt: 2 }}>CPT Description</InputLabel>
                    <CustomTextField value={editVisitReasonName} setValue={setEditVisitReasonName} />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>

                        <Button variant="contained" disableElevation onClick={handleUpdateVisitReason} color="primary">
                            {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Update"}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <CustomSnackbar
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </Box>
    );
};

export default VisitReasonBody;
